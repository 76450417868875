// Libraries
import React from "react";
import $ from "jquery";

// Modules
import "../App.css";
import { constants, } from "../constants";

// Prototype declarations
function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

class ComponentBoards extends React.Component {
    _isMounted = false;
    tableIntervals = [];

    constructor(props) {
        super(props);
        this.state = {
            productLines: null,
            productLineHeaders: {},
            headingFilter: [],
            excludeHeading: [],
            showNotes: null,
            selectedProductLine: "All",
            searchFilter: "",
            showAdvancedTools: true,
            undoCache: null,
            redoCache: null,
            showFilters: false,
            lastUpdatedMarker: null,
            showAddTable: false,
            editTableData: {'headers': [], 'rows': []},
            urlLocation: null,
            oldName: '',
            deleting: null,
            showFloatingNotes: null,
            floatingNotesTimer: null,
            confirmCopy: false,
            productLineOrder: [],
            boards: localStorage.getItem('boards'),
            editingTable: false,
            confirmCopyName: '',
            editingMode: false,
            processes: [],
            machines: [],
            showSchedulePicker: false,
            addProcesses: [],
            editShowNotes: false,
            weekAhead: 0,
            markerChanges: [],
            staticTableHeaders: [],
        };
        this.pressTimer = null;
        this.longPressed = false;
        this.visibleComponents = 0;
        this.updateCells = null;
        this.editIndex = -1;
        this.changedPath = null;
        // this.enterKeyPressed = this.enterKeyPressed.bind(this);
    };

    componentDidMount() {
        let location = window.location.href;
        let url = new URL(location);
        let board = url.searchParams.get("board");
        let selectedProductLine = this.state.selectedProductLine;
        
        if (board) {
            selectedProductLine = board;
            $('#board-select').val(selectedProductLine);
        };

        this.getMachines();
        this.getProcesses();

        this.setState({
            selectedProductLine: selectedProductLine,
            urlLocation: location,
        }, () => {
            this.changedPath = setInterval(() => {
                let location = window.location.href;

                if (location !== this.state.urlLocation) {
                    let url = new URL(location);
                    let board = url.searchParams.get("board");
                    let selectedProductLine = this.state.selectedProductLine;
                    
                    if (board) {
                        selectedProductLine = board;
                        $('#board-select').val(selectedProductLine);
                    };

                    this.setState({
                        selectedProductLine: selectedProductLine,
                        urlLocation: location,
                    });
                };

                let localBoards = localStorage.getItem('boards');

                if (localBoards !== this.state.boards) {
                    let updatedBoards = {};
                    localBoards = JSON.parse(localBoards);

                    for (let index=0; index < localBoards.length; index++) {
                        updatedBoards[localBoards[index]] = this.state.productLines[localBoards[index]];
                    };

                    this.setState({
                        productLines: updatedBoards,
                        boards: JSON.stringify(localBoards),
                    });
                };
            }, 250);
        });

        this._isMounted = true;
        this.getProductLineHeaders();
        this.getProductLines();
        
        $(window).on('scroll', () => {
            let overTable = false;
            let position = $(document).scrollTop() + 50;

            if (this.tableIntervals.length) {
                for (let index=0; index < this.tableIntervals.length; index++) {
                    if (this.tableIntervals[index][0] <= position && position <= this.tableIntervals[index][1]) {
                        overTable = true;
                        this.setState({
                            staticTableHeaders: this.state.productLineHeaders[Object.keys(this.state.productLineHeaders)[index]],
                        });
                    };
                };
            };

            if (overTable) {
                $('#static-table-header').css({display: 'flex',});
            } else {
                $('#static-table-header').css({display: 'none',});
            };
        });

        // $(document).on('keypress', this.enterKeyPressed);

        // Close modal by clicking out of it
        $(document).on("click", "body *", (event) => {
            let firstParent = ($(event.target).attr("class") || "").includes("modal-container");
            let secondParent = ($(event.target).parent().attr("class") || "").includes("modal-container");
            let thirdParent = ($(event.target).parent().parent().attr("class") || "").includes("modal-container");
            let fourthParent = ($(event.target).parent().parent().parent().attr("class") || "").includes("modal-container");

            if (this.state.showNotes) {
                if (!firstParent && !secondParent && !thirdParent && !fourthParent) {
                    this.setState({
                        showNotes: false,
                    });
                };
            };
        });

        this.updateCells = setInterval(() => {
            if (!this.state.editingMode) {
                fetch(`${constants.backendAddress}/api/get-edit-index/`).then((response) => {
                    return response.json();
                }).then((response) => {
                    if (parseInt(response) > this.editIndex) {
                        this.getProductLineHeaders();
                        this.getProductLines();
                        this.forceUpdate();
                        $(window).trigger('scroll');
                    };

                    this.editIndex = parseInt(response);
                });
            };
        }, 5000);

        $(document).on('keypress', (event) => {
            if (event.which == 13) {
                if (this.state.showNotes) {
                    let message = $("#sb-chat-input").val();
                    if (message) {
                        let productLinesClone = JSON.parse(JSON.stringify(this.state.productLines));
                        productLinesClone[this.state.showNotes[2]][this.state.showNotes[1]]["notes"].push({message: message, context: this.getMessageHeader()});
                        let markerChangesClone = JSON.parse(JSON.stringify(this.state.markerChanges));
                        markerChangesClone.push({
                            updatedMarker: productLinesClone[this.state.showNotes[2]][this.state.showNotes[1]],
                            boardIndex: this.state.showNotes[2],
                            rowIndex: this.state.showNotes[1],
                        });

                        this.setState({
                            markerChanges: markerChangesClone,
                            productLines: productLinesClone,
                        }, () => {
                            this.pushProductLineChanges();
                        });

                        $("#sb-chat-input").val("");
                    };
                } else if (this.state.showAddTable) {
                    if ($('#ab-new-row-input').val()) {
                        let etdCopy = {...this.state.editTableData};

                        etdCopy['rows'].push({'name': $('#ab-new-row-input').val(),
                                                'index': this.state.editTableData['rows'].length,
                                            });

                        this.setState({
                            editTableData: etdCopy,
                        });

                        $('#ab-new-row-input').val('');
                    } else if ($('#ab-new-header-input').val()) {
                        let etdCopy = {...this.state.editTableData};

                        etdCopy['headers'].push({'name': $('#ab-new-header-input').val(),
                                                'index': this.state.editTableData['headers'].length,
                                            });

                        this.setState({
                            editTableData: etdCopy,
                        });

                        $('#ab-new-header-input').val('');
                    };
                };
            };
        });
    };

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.updateCells);
        clearInterval(this.changedPath);
    };

    componentDidUpdate() {
        if (!this.tableIntervals.length) {
            this.displayTableHeader();
        };
    };

    addNewProcesses() {
        let processes = replaceAll(JSON.stringify(this.state.addProcesses), '/', '-');

        fetch(`${constants.backendAddress}/api/add-processes/?processes=${processes}`).then((response) => {
            return response.json();
        }).then((response) => {
            this.getProcesses();
        });
    };

    toggleBodyScroll() {
        if ($('body').css('overflow-y') === 'hidden') {
            $('body').css('overflow-y', 'auto');
        } else {
            $('body').css('overflow-y', 'hidden');
        };
    };

    sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    };

    getProcesses() {
        fetch(`${constants.backendAddress}/api/get-processes/`).then((response) => {
            return response.json();
        }).then((response) => {
            this.setState({
                processes: response,
            });
        });
    };

    getMachines() {
        fetch(`${constants.backendAddress}/api/get-machines/`).then((response) => {
            return response.json();
        }).then((response) => {
            this.setState({
                machines: JSON.parse(response),
            });
        });
    };

    dateBetween(startDate, endDate, refDate) {
        let startDateObj = new Date(startDate);
        let endDateObj = new Date(endDate);
        let refDateObj = new Date(refDate);

        if (startDateObj <= refDateObj && endDateObj >= refDateObj) {
            return true;
        } else {
            return false;
        };
    };

    displayCurrentProcess(part) {
        let process;

        for (let index=0; index < part['processes'].length; index++) {
            if (part['currentProcess'] === part['processes'][index]['title']) {
                process = part['processes'][index];
            };
        };

        if (process) {
            return (
                <>
                <td style={{overflow: 'hidden', whiteSpace: 'nowrap',}}>{process['assigned']}</td>
                <td style={{backgroundColor: this.fillIn(0, process['start_date'], process['due_date'])}}></td>
                <td style={{backgroundColor: this.fillIn(1, process['start_date'], process['due_date'])}}></td>
                <td style={{backgroundColor: this.fillIn(2, process['start_date'], process['due_date'])}}></td>
                <td style={{backgroundColor: this.fillIn(3, process['start_date'], process['due_date'])}}></td>
                <td style={{backgroundColor: this.fillIn(4, process['start_date'], process['due_date'])}}></td>
                <td style={{backgroundColor: this.fillIn(5, process['start_date'], process['due_date'])}}></td>
                <td style={{backgroundColor: this.fillIn(6, process['start_date'], process['due_date'])}}></td>
                </>
            );
        };

        return (
            <>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            </>
        );
    };

    getFutureDates(adder, ymd=false) {
        let date = new Date();
        let day = date.getDay();
        let prevMonday = new Date();
        if (date.getDay() == 0){
            prevMonday.setDate(date.getDate() - 7);
        }
        else {
            prevMonday.setDate(date.getDate() - (day-1));
        }

        let futureDates = [];
        let days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

        for (let dateAdder=0 + adder; dateAdder <= 6 + adder; dateAdder++) {
            let date = prevMonday.addDays(dateAdder);

            if (date.getDay() !== 0) {
                if (ymd) {
                    futureDates.push(`${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`);
                } else {
                    futureDates.push(`${days[date.getDay()]}, ${date.getMonth() + 1}/${date.getDate()}`);
                };
            };
        };

        return futureDates;
    };

    enterKeyPressed(event) {
        if (this.showAddTable) {
            if (event.which == 13) {
                let etdCopy = {...this.state.editTableData};
                let headerInput = $('#ab-new-header-input').val();
                let rowInput = $('#ab-new-row-input').val();

                if (headerInput) {
                    etdCopy['headers'].push({'name': headerInput,
                        'index': this.state.editTableData['headers'].length,
                    });
                };

                if (rowInput) {
                    etdCopy['rows'].push({'name': rowInput,
                        'index': this.state.editTableData['rows'].length,
                    });
                };

                this.setState({
                    editTableData: etdCopy,
                });

                $('#ab-new-header-input').val('');
                $('#ab-new-row-input').val('');
            };
        };
    };

    displayTableHeader() {
        let tableIntervals = [];
        
        $('table').each(function() {
            let parameters = this.getBoundingClientRect();
            tableIntervals.push([parameters['top'], parameters['bottom'] - 122]);
        });

        this.tableIntervals = tableIntervals;
    };

    getProductLines() {
        fetch(`${constants.backendAddress}/api/get-product-line-order/`).then((response) => {
            return response.json();
        }).then((response) => {
            this.setState({
                productLineOrder: JSON.parse(response),
            });
        }).then(() => {
            fetch(`${constants.backendAddress}/api/get-product-lines/`).then((response) => {
                return response.json();
            }).then((response) => {
                if (this._isMounted) {
                    let productLines = Object.keys(response);

                    for (let index=0; index < productLines.length; index++) {
                        let parsedProductLine = JSON.parse(response[productLines[index]]);
                        response[productLines[index]] = parsedProductLine;
                    };

                    let orderedProductLines = {};

                    for (let index=0; index < this.state.productLineOrder.length; index++) {
                        orderedProductLines[this.state.productLineOrder[index]] = response[this.state.productLineOrder[index]];
                    };

                    localStorage.setItem('boards', JSON.stringify(Object.keys(orderedProductLines)));

                    this.setState({
                        productLines: orderedProductLines,
                    });
                };
            });
        });
    };

    getProductLineHeaders() {
        fetch(`${constants.backendAddress}/api/get-product-line-headers/`).then((response) => {
            return response.json();
        }).then((response) => {
            if (this._isMounted) {
                let parsed = {};

                for (const [key, value] of Object.entries(response)) {
                    parsed[key] = JSON.parse(value);
                };

                this.setState({
                    productLineHeaders: parsed,
                });
            };
        });
    };

    saveProductLines(productLines=null) {
        // if (productLines) {
        //     if (!this.state.editingMode) {
        //         $('.fixed-location-strip-container').addClass('pulsing-location-strip-effect');
        //     };
        // } else {
        //     this.pushProductLineChanges();

        //     if (!this.state.editingMode) {
        //         $('.fixed-location-strip-container').removeClass('pulsing-location-strip-effect');
        //     };
        // };

        if (!productLines) {
            this.pushProductLineChanges();
        };
    };

    pushProductLineChanges(revert=false) {
        if (this.editIndex) {
            this.editIndex += 1;
        } else {
            this.editIndex = 1;
        };

        if (!revert) {
            // Copied from "getProductLines()"
            fetch(`${constants.backendAddress}/api/get-product-lines/`).then((response) => {
                return response.json();
            }).then((response) => {
                if (this._isMounted) {
                    let productLines = Object.keys(response);

                    for (let index=0; index < productLines.length; index++) {
                        let parsedProductLine = JSON.parse(response[productLines[index]]);
                        response[productLines[index]] = parsedProductLine;
                    };

                    let orderedProductLines = {};

                    for (let index=0; index < this.state.productLineOrder.length; index++) {
                        orderedProductLines[this.state.productLineOrder[index]] = response[this.state.productLineOrder[index]];
                    };

                    let lastMarkerChange = this.state.markerChanges.at(-1);
                    orderedProductLines[lastMarkerChange['boardIndex']][lastMarkerChange['rowIndex']] = lastMarkerChange['updatedMarker'];
                    fetch(`${constants.backendAddress}/api/save-product-lines/?contents=${JSON.stringify(orderedProductLines)}`);

                    this.setState({
                        editingMode: false,
                    });
                };
            });
        } else {
            this.setState({
                productLines: this.state.editingMode,
                editingMode: false,
            });
        };
    };

    updateMarker(event) {
        let xPosition = event.pageX - $(event.target).offset().left;
        let distanceToEdge = $(event.target).outerWidth() - xPosition;
        let target = $(event.target);

        if (!target.attr('class')) {
            target = target.parent();
        };

        if (target.css("background-color") === "rgb(208, 208, 208)") {
            event.stopPropagation();
            return;
        } else if (target.css("background-color") === "rgb(55, 135, 255)" && distanceToEdge > 15) {
            let location = target.attr("class").split("-");
            
            this.setState({
                showNotes: location,
                showFloatingNotes: false,
            });
        } else if (target.css("background-color") === "rgb(255, 0, 0)") {
            let location = target.parent().attr("class").split("-");

            this.setState({
                showNotes: location,
                showFloatingNotes: false,
            });
        } else {
            // Unshow notes
            this.setState({
                showNotes: false,
            });

            if (this.state.showAdvancedTools) {
                this.setState({
                    redoCache: null,
                    lastUpdatedMarker: event.pageY - target.outerHeight() - (0.5 * $(window).height()),
                });

                let location = target.attr("class").split("-");
                let clonedProductLines = JSON.parse(JSON.stringify(this.state.productLines));
                clonedProductLines[location[2]][location[1]]["highlightedIndex"] = parseInt(location[0]);

                // Clear notes if "In Stock"
                // if (parseInt(location[0]) === 8) {
                //     clonedProductLines[location[2]][location[1]]["notes"] = [];
                // };

                if (distanceToEdge <= 15 && location[0] + 1 < Object.keys(this.state.productLineHeaders[location[2]]).length) {
                    clonedProductLines[location[2]][location[1]]["splitMarker"] = true;
                } else {
                    clonedProductLines[location[2]][location[1]]["splitMarker"] = false;
                };

                let markerChangesClone = JSON.parse(JSON.stringify(this.state.markerChanges));
                markerChangesClone.push({
                    updatedMarker: clonedProductLines[location[2]][location[1]],
                    boardIndex: location[2],
                    rowIndex: location[1],
                });

                this.setState({
                    markerChanges: markerChangesClone,
                    productLines: clonedProductLines,
                    undoCache: {
                        updatedMarker: this.state.productLines[location[2]][location[1]],
                        boardIndex: location[2],
                        rowIndex: location[1],
                    },
                });
                if (!this.state.editingMode) {
                    this.setState({
                        editingMode: this.state.productLines,
                    });

                    // $('.page-nav').on('click', (event) => {
                    //     event.preventDefault();

                    //     if (window.confirm('You have unsaved changes. Switching pages will undo these changes.')) {
                    //         window.location.href = $($(event.target).parent()).attr('href');
                    //     };
                    // });
                };

                this.sleep(500).then(() => {
                    this.setState({
                        showSchedulePicker: {
                            'part': location[1],
                            'process': this.state.productLineHeaders[location[2]][parseInt(location[0])],
                            'productLine': location[2],
                        },
                    });
                    this.toggleBodyScroll();
                });
                this.saveProductLines(clonedProductLines);
            };
        };

        event.stopPropagation();
    };

    shiftMarker(direction, event, split=false) {
        let target = $(event.target);

        this.setState({
            redoCache: null,
            lastUpdatedMarker: event.pageY - target.outerHeight() - (0.5 * $(window).height()),
        });
        
        let location = target.parent().parent().attr("class").split("-");
        let clonedProductLines = JSON.parse(JSON.stringify(this.state.productLines));
        let totalIndices = this.state.productLineHeaders[location[2]].length;
        let greyIndices = clonedProductLines[location[2]][location[1]]["greyIndices"];
        let possibleIndices = [];

        for (let index=0; index < totalIndices; index++) {
            if (!greyIndices.includes(index)) {
                possibleIndices.push(index);
            };
        };

        let newIndex = possibleIndices.indexOf(parseInt(location[0])) + direction;
        
        if (newIndex < 0) {
            newIndex = 0;
            
            if (!split) {
                split = true;
            };
        } else if (newIndex > possibleIndices.length - 1) {
            newIndex = possibleIndices.length - 1;

            if (split) {
                split = false;
            };
        };

        let newLocation = possibleIndices[newIndex];

        if (direction === 1) {
            if (!split) {
                if (location[0] < totalIndices - 1) {
                    clonedProductLines[location[2]][location[1]]["splitMarker"] = true;
                };
            } else {
                clonedProductLines[location[2]][location[1]]["splitMarker"] = false;
                clonedProductLines[location[2]][location[1]]["highlightedIndex"] = newLocation;
            };
        } else if (direction === -1) {
            if (split) {
                clonedProductLines[location[2]][location[1]]["splitMarker"] = false;
            } else {
                if (newLocation >= 0) {
                    clonedProductLines[location[2]][location[1]]["splitMarker"] = true;
                    clonedProductLines[location[2]][location[1]]["highlightedIndex"] = newLocation;
                };
            };
        };

        // Clear notes if "In Stock"
        // if (parseInt(location[0]) === 8) {
        //     clonedProductLines[location[2]][location[1]]["notes"] = [];
        // };

        let markerChangesClone = JSON.parse(JSON.stringify(this.state.markerChanges));
        markerChangesClone.push({
            updatedMarker: clonedProductLines[location[2]][location[1]],
            boardIndex: location[2],
            rowIndex: location[1],
        });

        this.setState({
            markerChanges: markerChangesClone,
            productLines: clonedProductLines,
            undoCache: {
                updatedMarker: this.state.productLines[location[2]][location[1]],
                boardIndex: location[2],
                rowIndex: location[1],
            },
        });
        if (!this.state.editingMode) {
            this.setState({
                editingMode: this.state.productLines,
            });

            // $('.page-nav').on('click', (event) => {
            //     event.preventDefault();

            //     $('.page-nav').on('click', (event) => {
            //         event.preventDefault();

            //         if (window.confirm('You have unsaved changes. Switching pages will undo these changes.')) {
            //             window.location.href = $($(event.target).parent()).attr('href');
            //         };
            //     });
            // });
        };

        this.saveProductLines(clonedProductLines);
    };

    greyOutCell(event) {
        if (this.state.showAdvancedTools) {
            let location = $(event.target).attr("class").split("-");
            let clonedProductLines = JSON.parse(JSON.stringify(this.state.productLines));
            
            if ($(event.target).css("background-color") === "rgb(208, 208, 208)") {
                clonedProductLines[location[2]][location[1]]["greyIndices"].splice(clonedProductLines[location[2]][location[1]]["greyIndices"].indexOf(parseInt(location[0])), 1);
            } else {
                clonedProductLines[location[2]][location[1]]["greyIndices"] = [...clonedProductLines[location[2]][location[1]]["greyIndices"], parseInt(location[0])];
            };

            let markerChangesClone = JSON.parse(JSON.stringify(this.state.markerChanges));
            markerChangesClone.push({
                updatedMarker: clonedProductLines[location[2]][location[1]],
                boardIndex: location[2],
                rowIndex: location[1],
            });

            this.setState({
                markerChanges: markerChangesClone,
                productLines: clonedProductLines,
            }, () => {
                this.pushProductLineChanges();
            });
        };        
    };

    filterByHeading(event) {
        let index = $(event.target).html();

        if (this.state.headingFilter.includes(index)) {
            let headingFilterClone = JSON.parse(JSON.stringify(this.state.headingFilter));
            headingFilterClone.splice(headingFilterClone.indexOf(index), 1)

            this.setState({
                headingFilter: headingFilterClone,
            });
        } else if (this.state.excludeHeading.includes(index)) {
            let excludeHeadingClone = JSON.parse(JSON.stringify(this.state.excludeHeading));
            excludeHeadingClone.splice(excludeHeadingClone.indexOf(index), 1)

            this.setState({
                excludeHeading: excludeHeadingClone,
            });
        } else {
            this.setState({
                headingFilter: [...this.state.headingFilter, index],
            });
        };
    };

    excludeHeading(event) {
        let index = $(event.target).html();

        if (this.state.excludeHeading.includes(index)) {
            let excludeHeadingClone = JSON.parse(JSON.stringify(this.state.excludeHeading));
            excludeHeadingClone.splice(excludeHeadingClone.indexOf(index), 1)

            this.setState({
                excludeHeading: excludeHeadingClone,
            });
        } else {
            this.setState({
                excludeHeading: [...this.state.excludeHeading, index],
            });  
        };
    };

    showAddTable(productLine, editing=false) {
        let headers = [];
        let rows = [];
        let headers_list = this.state.productLineHeaders[productLine];
        let rows_list = Object.keys(this.state.productLines[productLine]);

        for (let index=0; index < headers_list.length; index++) {
            headers.push({
                'index': index,
                'name': headers_list[index],
            });
        };

        for (let index=0; index < rows_list.length; index++) {
            rows.push({
                'index': index,
                'name': rows_list[index],
            });
        };

        let editTableData = {
            'headers': headers,
            'rows': rows,
        };

        let editTitle = false;
        if (editing) {
            editTitle = productLine;
        };

        this.setState({
            editTableData: editTableData,
            showAddTable: true,
            editingTable: editTitle,
        }, () => {
            $('#table-input-error').css('display', 'none',);

            $('#header-inputs').find('input').each(function(index) {
                $(this).val(editTableData['headers'][index]['name']);
            });

            $('#row-inputs').find('input').each(function(index) {
                $(this).val(editTableData['rows'][index]['name']);
            });

            $('#ab-name-input').val(productLine);

            this.setState({
                oldName: productLine,
            });
        });
    };

    buildTable(productLine, index, headers, selectedProductLine=false) {
        if (!headers) {
            headers = [];
        };

        let tableContents = this.buildTableContents(productLine, headers);

        // Reset this.visisbleComponents
        if (index === 0 || selectedProductLine) {
            this.visibleComponents = 0;
        };

        const tableContainer = (
            <>
            <div
                className="content-title"
                style={
                    index > 0 && productLine !== this.state.selectedProductLine ? (
                        {fontWeight: "600", marginTop: "15px"}
                    ) : (
                        {fontWeight: "600"}
                    )
                }
            >
                {productLine}
                {
                    this.state.showAdvancedTools ? (
                        <i className="fas fa-edit"
                            style={{marginBottom: '-10px', cursor: 'pointer', position: 'absolute', right: '50px', color: '#c5c5c5'}}
                            title='Edit Board'
                            onClick={() => {
                                this.showAddTable(productLine, true);
                            }}
                        ></i>
                    ) : null
                }
                {
                    this.state.showAdvancedTools ? (
                        <i className="fas fa-copy"
                            style={{cursor: 'pointer', color: '#c5c5c5', marginBottom: '-10px', position: 'absolute', right: '25px',}}
                            title='Copy Board'
                            onClick={() => {
                                this.setState({
                                    confirmCopy: productLine,
                                    confirmCopyName: `Copy of ${productLine}`,
                                });
                            }}
                        ></i>
                    ) : null
                }
            </div>
            <div
                className="content-table-container"
                id="sb-content-table-container"
            >
                <table
                    className="content-table status-boards-table"
                    id={`sb-${productLine.toLowerCase().replace(/-/g, " ")}`}
                >
                    <thead>
                        <tr className='th-row'>
                            <th style={{cursor: "default", width: '200px'}}>Name</th>
                            {
                                headers.map((value, index) => (
                                    <><th
                                        className={index}
                                        onClick={(event) => {
                                            if (!this.longPressed) {
                                                this.filterByHeading(event);
                                            } else {
                                                this.longPressed = false;
                                            };
                                        }}
                                        onMouseUp={() => {
                                            clearTimeout(this.pressTimer);
                                        }}
                                        onMouseDown={(event) => {
                                            this.longPressed = false;
                                            this.pressTimer = window.setTimeout(() => {
                                                this.longPressed = true;
                                                this.excludeHeading(event);
                                            }, 500);
                                        }}
                                        style={
                                            this.state.headingFilter.includes(value) ? (
                                                {backgroundColor: "var(--washed-out-blue)", borderBottom: "1px solid var(--washed-out-blue)", color: "#ffffff",}
                                            ) : (
                                                this.state.excludeHeading.includes(value) ? (
                                                    {backgroundColor: "rgb(208, 208, 208)", color: "rgb(0, 100, 100)",}
                                                ) : (
                                                    null
                                                )
                                            )
                                        }
                                    >{value}</th></>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tableContents["contents"]
                        }
                        {
                            <tr style={{display: "none",}}><td>{
                                this.visibleComponents += tableContents["visibleComponents"]
                            }</td></tr>
                        }
                    </tbody>
                </table>
            </div></>
        );

        if (tableContents["visibleComponents"] > 0 || this.state.searchFilter === "") {
            return tableContainer;
        } else if (index + 1 === Object.keys((this.state.productLines || {})).length || selectedProductLine) {
            // Check if no visible components
            if (this.visibleComponents === 0) {
                this.setState({
                    searchFilter: "",
                });
            };
        };
    };

    buildTableContents(productLine, headers) {
        let components = this.state.productLines[productLine];
        let visibleComponents = 0;
        
        if (!headers) {
            headers = [];
        };

        const contents = (
            Object.keys(components).map((component) => (
                (component || "").toLowerCase().includes(this.state.searchFilter) || (productLine || "").toLowerCase().includes(this.state.searchFilter) ? (
                    !this.state.headingFilter.length || this.state.headingFilter.includes(this.state.productLineHeaders[productLine][parseInt(components[component]["highlightedIndex"])]) ? (
                        !this.state.excludeHeading.includes(this.state.productLineHeaders[productLine][parseInt(components[component]["highlightedIndex"])]) ? (
                            <tr>
                                <td style={{boxSizing: "border-box", whiteSpace: 'nowrap', overflow: 'hidden', padding: "7px 10px 7px 10px", cursor: "default",}}>{component}</td>
                                {/* <td style={{boxSizing: "border-box", padding: "7px 10px 7px 10px", cursor: "default",}}>{components[component]["location"]}</td> */}
                                {
                                    [...Array(headers.length)].map((value, index) => (
                                        <>
                                            {
                                                (components[component]["greyIndices"] || []).includes(index) ? (
                                                    <td
                                                        className={`${index}-${component}-${productLine}`}
                                                        style={{textAlign: "right", backgroundColor: "#d0d0d0",}}
                                                        onMouseUp={() => {
                                                            clearTimeout(this.pressTimer);
                                                        }}
                                                        onMouseDown={(event) => {
                                                            this.pressTimer = window.setTimeout(() => {
                                                                this.greyOutCell(event);
                                                            }, 500);
                                                            this.longPressed = true;
                                                        }}
                                                    ></td>
                                                ) : (
                                                    components[component]["splitMarker"] && parseInt(index) === parseInt(components[component]["highlightedIndex"]) ? (
                                                        <td
                                                            className={`${index}-${component}-${productLine}`}
                                                            onClick={(event) => {
                                                                this.updateMarker(event);
                                                            }}
                                                            onMouseOver={(event) => {
                                                                if (components[component]['notes'].length) {
                                                                    let outerEvent = event;
                                                                    let floatingNotesTimer = window.setTimeout(() => {
                                                                        let location = null;
                                                                        let target = $(outerEvent.target);

                                                                        if (target.parent().is('td')) {
                                                                            location = target.parent().attr("class").split("-");

                                                                            this.setState({
                                                                                showFloatingNotes: location,
                                                                            }, () => {
                                                                                let height = $('#floating-notes-container').outerHeight();
                                                                                let width = $('#floating-notes-container').outerWidth();
                                                                                let position = $(target.parent())[0].getBoundingClientRect();
                                                                                let parentWidth = $(target.parent()).outerWidth();

                                                                                if (position['left'] + width + parentWidth - 35 < $(window).width()) {
                                                                                    $('#floating-notes-container').css({position: 'absolute', top: `${position['top'] + document.documentElement.scrollTop - height - 95}px`, left: `${position['left'] + parentWidth - 70}px`});
                                                                                } else {
                                                                                    $('#floating-notes-container').css({position: 'absolute', top: `${position['top'] + document.documentElement.scrollTop - height - 95}px`, left: `${position['left'] + parentWidth - 10 - width}px`});
                                                                                };
                                                                            });
                                                                        };
                                                                    }, 1000);

                                                                    this.setState({
                                                                        floatingNotesTimer: floatingNotesTimer,
                                                                    });
                                                                };
                                                            }}
                                                            onMouseOut={() => {
                                                                clearInterval(this.state.floatingNotesTimer);

                                                                this.setState({
                                                                    showFloatingNotes: null,
                                                                });
                                                            }}
                                                            style={{textAlign: "right",}}
                                                        >
                                                            {/* Split marker */}
                                                            <div
                                                                className="sb-split-marker"
                                                                style={{cursor: "pointer", display: 'flex', alignItems: 'center',  position: 'relative', borderBottom: "2px solid #ff0000"}}
                                                            >
                                                                {
                                                                    components[component]['notes'].length ? (
                                                                        <div
                                                                            style={{top: '2px', right: '6px',}}
                                                                            className='marker-info-icon'
                                                                        >
                                                                            <i className='fas fa-flag'></i>
                                                                        </div>
                                                                    ) : null
                                                                }
                                                                <i className={`fas fa-arrow-left ${this.state.showAdvancedTools ? 'table-arrow-icon' : 'hide-ta-icon'}`} style={{marginLeft: '10px',}}
                                                                    onClick={(event) => {
                                                                        this.shiftMarker(-1, event, true);
                                                                        event.stopPropagation();
                                                                    }}
                                                                ></i>
                                                                <i className={`fas fa-arrow-right ${this.state.showAdvancedTools ? 'table-arrow-icon' : 'hide-ta-icon'}`} style={{marginLeft: 'auto', marginRight: '10px'}}
                                                                    onClick={(event) => {
                                                                        this.shiftMarker(1, event, true);
                                                                        event.stopPropagation();
                                                                    }}
                                                                    onMouseOver={(event) => {
                                                                        let target = $(event.target);
                                                                        $(target.parent()).find('.marker-info-icon i').css('color', 'rgba(255, 255, 255, 0)');
                                                                    }}
                                                                    onMouseLeave={(event) => {
                                                                        let target = $(event.target);
                                                                        $(target.parent()).find('.marker-info-icon i').css('color', 'rgba(255, 255, 255, 0.65)');
                                                                    }}
                                                                ></i>
                                                            </div>
                                                        </td>
                                                    ) : (
                                                        parseInt(index) === parseInt(components[component]["highlightedIndex"]) ? (
                                                            // Blue marker
                                                            <td
                                                                className={`${index}-${component}-${productLine}`}
                                                                style={{backgroundColor: "var(--washed-out-blue)", borderBottom: "1px solid var(--washed-out-blue)", cursor: "pointer", position: 'relative'}}
                                                                onClick={(event) => {
                                                                    this.updateMarker(event);
                                                                }}
                                                                onMouseOver={(event) => {
                                                                    if (components[component]['notes'].length) {
                                                                        let outerEvent = event;
                                                                        let floatingNotesTimer = window.setTimeout(() => {
                                                                            let location = null;
                                                                            let target = $(outerEvent.target);

                                                                            if (target.is('td')) {
                                                                                location = target.attr("class").split("-");

                                                                                this.setState({
                                                                                    showFloatingNotes: location,
                                                                                }, () => {
                                                                                    let height = $('#floating-notes-container').outerHeight();
                                                                                    let width = $('#floating-notes-container').outerWidth();
                                                                                    let position = $(target)[0].getBoundingClientRect();
                                                                                    let parentWidth = $(target).outerWidth();

                                                                                    if (position['left'] + width < $(window).width()) {
                                                                                        $('#floating-notes-container').css({position: 'absolute', top: `${position['top'] + document.documentElement.scrollTop - height - 95}px`, left: `${position['left'] - 70}px`});
                                                                                    } else {
                                                                                        $('#floating-notes-container').css({position: 'absolute', top: `${position['top'] + document.documentElement.scrollTop - height - 95}px`, left: `${position['left'] - 70 - width + parentWidth}px`});
                                                                                    };
                                                                                });
                                                                            } else if ($(target).parent().is('td')) {
                                                                                target = $(target).parent();
                                                                                location = target.attr("class").split("-");

                                                                                this.setState({
                                                                                    showFloatingNotes: location,
                                                                                }, () => {
                                                                                    let height = $('#floating-notes-container').outerHeight();
                                                                                    let width = $('#floating-notes-container').outerWidth();
                                                                                    let position = $(target)[0].getBoundingClientRect();
                                                                                    let parentWidth = $(target).outerWidth();

                                                                                    if (position['left'] + width < $(window).width()) {
                                                                                        $('#floating-notes-container').css({position: 'absolute', top: `${position['top'] + document.documentElement.scrollTop - height - 95}px`, left: `${position['left'] - 70}px`});
                                                                                    } else {
                                                                                        $('#floating-notes-container').css({position: 'absolute', top: `${position['top'] + document.documentElement.scrollTop - height - 95}px`, left: `${position['left'] - 70 - width + parentWidth}px`});
                                                                                    };
                                                                                });
                                                                            };
                                                                        }, 1000);

                                                                        this.setState({
                                                                            floatingNotesTimer: floatingNotesTimer,
                                                                        });
                                                                    };
                                                                }}
                                                                onMouseOut={() => {
                                                                    clearInterval(this.state.floatingNotesTimer);

                                                                    this.setState({
                                                                        showFloatingNotes: null,
                                                                    });
                                                                }}
                                                            >
                                                                {
                                                                    components[component]['notes'].length ? (
                                                                        <div
                                                                            style={{top: '3px', right: '7px',}}
                                                                            className='marker-info-icon'
                                                                        >
                                                                            <i className='fas fa-flag'></i>
                                                                        </div>
                                                                    ) : null
                                                                }
                                                                <div
                                                                    style={{display: 'flex', alignItems: 'center',}}
                                                                >
                                                                    <i className={`fas fa-arrow-left ${this.state.showAdvancedTools ? 'table-arrow-icon' : 'hide-ta-icon'}`} style={{marginLeft: '-5px',}}
                                                                        onClick={(event) => {
                                                                            this.shiftMarker(-1, event);
                                                                            event.stopPropagation();
                                                                        }}
                                                                    ></i>
                                                                    <i className={`fas fa-arrow-right ${this.state.showAdvancedTools ? 'table-arrow-icon' : 'hide-ta-icon'}`} style={{marginLeft: 'auto', marginRight: '10px'}}
                                                                        onClick={(event) => {
                                                                            this.shiftMarker(1, event);
                                                                            event.stopPropagation();
                                                                        }}
                                                                        onMouseOver={(event) => {
                                                                            let target = $(event.target);
                                                                            $($(target.parent()).parent()).find('.marker-info-icon i').css('color', 'rgba(255, 255, 255, 0)');
                                                                        }}
                                                                        onMouseLeave={(event) => {
                                                                            let target = $(event.target);
                                                                            $($(target.parent()).parent()).find('.marker-info-icon i').css('color', 'rgba(255, 255, 255, 0.65)');
                                                                        }}
                                                                    ></i>
                                                                </div>
                                                            </td>
                                                        ) : (
                                                            <td
                                                                className={`${index}-${component}-${productLine}`}
                                                                onClick={(event) => {
                                                                    if (!this.longPressed) {
                                                                        this.updateMarker(event);
                                                                    } else {
                                                                        this.longPressed = false;
                                                                    };
                                                                }}
                                                                style={
                                                                    this.state.showAdvancedTools ? (
                                                                        {cursor: "pointer",}
                                                                    ) : (
                                                                        null
                                                                    )
                                                                }
                                                                onMouseUp={() => {
                                                                    clearTimeout(this.pressTimer);
                                                                }}
                                                                onMouseDown={(event) => {
                                                                    this.longPressed = false;
                                                                    this.pressTimer = window.setTimeout(() => {
                                                                        this.greyOutCell(event);
                                                                        this.longPressed = true;
                                                                    }, 500);
                                                                }}
                                                            ></td>
                                                        )
                                                    )
                                                )
                                            }
                                        </>
                                    ))
                                }
                                <td style={{display: "none",}}>
                                    {
                                        visibleComponents += 1
                                    }
                                </td>
                            </tr>
                        ) : (
                            null
                        )
                    ) : (
                        null
                    )
                ) : (
                    null
                )
            ))
        );

        return {"contents": contents, "visibleComponents": visibleComponents,};
    };

    undoChanges() {
        if (this.state.undoCache) {
            let markerChangesClone = JSON.parse(JSON.stringify(this.state.markerChanges));
            markerChangesClone.push(this.state.undoCache);

            let productLinesClone = JSON.parse(JSON.stringify(this.state.productLines));
            productLinesClone[this.state.undoCache['boardIndex']][this.state.undoCache['rowIndex']] = this.state.undoCache['updatedMarker'];

            this.setState({
                productLines: productLinesClone,
                markerChanges: markerChangesClone,
                redoCache: this.state.markerChanges.at(-1),
                undoCache: null,
            }, () => {
                this.pushProductLineChanges();
            });
        };

        if (this.state.lastUpdatedMarker) {
            window.scrollTo({top: this.state.lastUpdatedMarker, behavior: "smooth",});
            // $(`.${this.state.lastUpdatedMarker}`)[0].scrollIntoView({
            //     behavior: "smooth",
            //     block: "start"
            // });
        };
    };

    redoChanges() {
        if (this.state.redoCache) {
            let markerChangesClone = JSON.parse(JSON.stringify(this.state.markerChanges));
            markerChangesClone.push(this.state.redoCache);

            let productLinesClone = JSON.parse(JSON.stringify(this.state.productLines));
            productLinesClone[this.state.redoCache['boardIndex']][this.state.redoCache['rowIndex']] = this.state.redoCache['updatedMarker'];

            this.setState({
                productLines: productLinesClone,
                markerChanges: markerChangesClone,
                undoCache: this.state.markerChanges.at(-1),
                redoCache: null,
            }, () => {
                this.pushProductLineChanges();
            });
        };

        if (this.state.lastUpdatedMarker) {
            window.scrollTo({top: this.state.lastUpdatedMarker, behavior: "smooth",});
            // $(`.${this.state.lastUpdatedMarker}`)[0].scrollIntoView({
            //     behavior: "smooth",
            //     block: "start"
            // });
        };
    };

    toggleFilters() {
        if (this.state.showFilters) {
            this.setState({
                showFilters: false,
            });
        } else {
            this.setState({
                showFilters: true,
            });
        };
    };

    /**
     * Generates the message header for a message-note.
     */
    getMessageHeader() {
        let headers = this.state.productLineHeaders[this.state.showNotes[2]];
        let location = headers[parseInt(this.state.productLines[this.state.showNotes[2]][this.state.showNotes[1]]["highlightedIndex"])];
        let date = new Date(Date.now()).toLocaleTimeString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
        let user = JSON.parse(localStorage.getItem("userSettings"))["preferred_name"];

        return `${user}, "${location}", ${date}`
    };

    render() {
        // Hover effect on "undo" and "redo" buttons
        $("#sb-actions-container i").on("mouseenter", (event) => {
            if ($(event.target).css("color") === "rgb(55, 135, 255)") {
                $(event.target).css("filter", "brightness(1.25)",);
            };
        });

        $("#sb-actions-container i").on("mouseleave", (event) => {
            $(event.target).css("filter", "brightness(1)",);
        });
        
        return (
            <>
                {/* {
                    this.state.editingMode ? (
                        <div
                            style={{top: `${parseInt(this.props.headerHeight)}px`}}
                            className='component-board-header-button-container'
                        >
                            <div
                                className='component-board-header-button'
                                style={{marginLeft: 'auto'}}
                                onClick={() => {
                                    this.pushProductLineChanges();
                                    // $('.fixed-location-strip-container').removeClass('pulsing-location-strip-effect');

                                    this.setState({
                                        editingMode: false,
                                    });

                                    $('.page-nav').prop("onclick", null).off("click");
                                }}
                            >Save Changes</div>
                            <div 
                                className='component-board-header-button'
                                style={{marginRight: '10px'}}
                                onClick={() => {
                                    // $('.fixed-location-strip-container').removeClass('pulsing-location-strip-effect');
                                    
                                    this.setState({
                                        productLine: this.state.editingMode,
                                    }, () => {
                                        this.pushProductLineChanges(true);
                                    });
                                }}
                            >
                                Cancel
                            </div>
                        </div>
                    ) : null
                } */}
                <div id='static-table-header'>
                    <div style={{width: '200px'}}>Name</div>
                    {
                        this.state.staticTableHeaders.map((header) => (
                            <div>{header}</div>
                        ))
                    }
                </div>
                <div className="content-container" style={{paddingBottom: 0, marginBottom: "-20px", paddingBottom: 0,}}>
                    <div style={{display: 'flex',}}>
                        <div style={{width: '100%'}}>
                            <div className="content-search-bar-container" style={{marginBottom: "5px",}}>
                                <i className="fas fa-search" style={{fontSize: "15px",}}></i>
                                <input
                                    placeholder="Search"
                                    style={{fontSize: "15px",}}
                                    onChange={(event) => {
                                        this.setState({
                                            searchFilter: event.target.value.toLowerCase(),
                                        });
                                    }}
                                ></input>
                                {/* <div
                                    id="sb-search-clear"
                                    onClick={() => {
                                        $(".content-search-bar-container input").val("");
                                        this.setState({
                                            searchFilter: "",
                                        });
                                    }}
                                >
                                    <i className="fas fa-times"></i>
                                </div> */}
                            </div>
                        </div>
                        <div id="sb-filter-container" style={{margin: 0, padding: 0,}}>
                            <div className="sb-filter-select" style={{margin: '1px 0 0 14px',}}>
                                <select
                                    id="board-select"
                                    onChange={() => {
                                        this.setState({
                                            selectedProductLine: $("#board-select").val(),
                                        });
                                    }}
                                    style={{backgroundColor: 'white', border: '1px solid #d0d0d0', borderRadius: '9px', color: '#808080', padding: '5px 0 5px 7px',}}
                                >
                                    <option>All</option>
                                    {
                                        Object.keys((this.state.productLines || {})).map((productLine, index) => (
                                            <option>{productLine}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="content-container"
                    style={{minHeight: "75px", paddingBottom: 0,}}
                >
                    {
                        Object.keys((this.state.productLines || {})).map((productLine, index) => (
                            this.state.productLines[productLine] ? (
                                this.state.selectedProductLine === "All" ? (
                                    <>
                                    {
                                        this.buildTable(productLine, index, this.state.productLineHeaders[productLine])
                                    }
                                    </>
                                ) : (
                                    productLine === this.state.selectedProductLine ? (
                                        <>
                                        {
                                            this.buildTable(productLine, index, this.state.productLineHeaders[productLine], true)
                                        }
                                        </>
                                    ) : (
                                        null
                                    )
                                )
                            ) : null
                        ))
                    }
                </div>

                <div id="sb-actions-container" className='sb-vert'>
                    {
                        this.state.showAdvancedTools ? (
                            <><i
                                className="fas fa-undo"
                                title='Undo'
                                onClick={() => {
                                    this.undoChanges();
                                }}
                                style={
                                    this.state.undoCache ? (
                                        {color: "var(--washed-out-blue)",}
                                    ) : (
                                        {cursor: "default", filter: "brightness(1)",}
                                    )
                                }
                            ></i>
                            <i
                                className="fas fa-redo"
                                title='Redo'
                                onClick={() => {
                                    this.redoChanges();
                                }}
                                style={
                                    this.state.redoCache ? (
                                        {color: "var(--washed-out-blue)",}
                                    ) : (
                                        {cursor: "default", filter: "brightness(1)",}
                                    )
                                }
                            ></i></>
                        ) : (
                            null
                        )
                    }
                    {
                        this.state.showAdvancedTools ? (
                            <i
                                className="fas fa-plus-circle"
                                title='Add Board'
                                onClick={() => {
                                    this.setState({
                                        showAddTable: true,
                                        editTableData: {'headers': [], 'rows': []},
                                    }, () => {
                                        $('#ab-name-input').val('');
                                        $('#table-input-error').css('display', 'none',);
                                        
                                        this.setState({
                                            oldName: '',
                                        });
                                    });
                                }}
                            >
                            </i>
                        ) : null
                    }
                    {/* <a
                        href='/boards/full-screen'
                        className='page-nav'
                    >
                        <i
                            className="fas fa-expand"
                            title='Expand'
                            id='expand-icon'
                        ></i>
                    </a> */}
                    {/* {
                        JSON.parse(sessionStorage.getItem("userSettings"))["permissions"] >= 1 ? (
                            <i
                                onClick={() => {
                                    if (this.state.showAdvancedTools) {
                                        this.setState({
                                            showAdvancedTools: false,
                                        });
                                        this.props.onToggleEdit();
                                    } else {
                                        this.setState({
                                            showAdvancedTools: true,
                                            undoCache: null,
                                            redoCache: null,
                                        });
                                        this.props.onToggleEdit();
                                    };
                                }}
                                className={
                                    this.state.showAdvancedTools ? ("fas fa-eye") : ("far fa-edit")
                                }
                                title={
                                    this.state.showAdvancedTools ? 'View Only' : 'Edit'
                                }
                                style={
                                    this.state.showAdvancedTools ? {} : {filter: "brightness(1)"}
                                }
                            ></i>
                        ) : null
                    } */}
                </div>

                {/* Note modal */}
                {
                    this.state.showNotes ? (
                        <div id='notes-container' className="modal-container" style={{display: "block",}}>
                            <div style={{display:"flex", alignItems:"center",}}>
                                <div className="content-title">
                                    <i className="fas fa-comments" style={{color: "var(--deep-blue)",}}></i>
                                    <span>"{this.state.showNotes[1]}" Notes</span>
                                </div>
                                <div className="modal-exit-container">
                                    <i
                                        className="fas fa-times-circle"
                                        onClick={() => {
                                            this.setState({
                                                showNotes: false,
                                            });
                                        }}
                                    ></i>
                                </div>
                            </div>
                            <div style={{marginTop: '-10px'}}>
                                <div style={{fontSize: '16px', marginBottom: '10px', color: '#a0a0a0', cursor: 'pointer', display: 'inline-block'}}
                                    onClick={() => {
                                        if (!this.state.editShowNotes) {
                                            this.setState({
                                                editShowNotes: true,
                                            });
                                        } else {
                                            this.setState({
                                                editShowNotes: false,
                                            });
                                        }
                                    }}
                                >
                                    {
                                        !this.state.editShowNotes ? (
                                            <><i className="fas fa-edit" style={{marginRight: '3px'}}></i>
                                            <span>Edit Notes</span>
                                            </>
                                        ) : (
                                            <div style={{color: 'var(--washed-out-blue)'}}>
                                            <i className="fas fa-check" style={{marginRight: '3px'}}></i>
                                            <span>Done Editing</span>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="sb-modal-chat-container">
                                {
                                    this.state.productLines[this.state.showNotes[2]][this.state.showNotes[1]]["notes"].length > 0 ? (
                                        (this.state.productLines[this.state.showNotes[2]][this.state.showNotes[1]]["notes"] || []).reverse().map((message, index) => (
                                            <>
                                            <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
                                                <div
                                                    className="sb-chat-message"
                                                    style={
                                                        index === 0 ? {
                                                            marginBottom: 0,
                                                        } : null
                                                    }
                                                >{message["message"]}
                                                </div>
                                                {
                                                    this.state.editShowNotes ? (
                                                        <>
                                                        <div style={{flexGrow: 1}}></div>
                                                        <i
                                                            className="fas fa-trash-alt" style={{fontSize: '22px', cursor: 'pointer', color: 'var(--washed-out-blue)', position: 'relative', top: '-5px'}}
                                                            onClick={() => {
                                                                let productLinesClone = JSON.parse(JSON.stringify(this.state.productLines));
                                                                productLinesClone[this.state.showNotes[2]][this.state.showNotes[1]]["notes"].splice(-1 * index, 1);
                                                                let markerChangesClone = JSON.parse(JSON.stringify(this.state.markerChanges));
                                                                markerChangesClone.push({
                                                                    updatedMarker: productLinesClone[this.state.showNotes[2]][this.state.showNotes[1]],
                                                                    boardIndex: this.state.showNotes[2],
                                                                    rowIndex: this.state.showNotes[1],
                                                                });

                                                                this.setState({
                                                                    markerChanges: markerChangesClone,
                                                                    productLines: productLinesClone,
                                                                }, () => {
                                                                    this.pushProductLineChanges();
                                                                });
                                                            }}
                                                        ></i>
                                                        </>
                                                    ) : null
                                                }
                                            </div>
                                            <div className="sb-chat-header">
                                                <span>{message["context"]}:</span>
                                            </div>
                                            </>
                                        ))
                                    ) : (
                                        `There are no active messages.`
                                    )
                                }
                            </div>
                            <div style={{display: "flex", alignItems: "center", marginTop: "20px",}}>
                                <div className="sb-chat-input-container">
                                    <input
                                        placeholder="Add a note..."
                                        id="sb-chat-input"
                                    />
                                </div>
                                <i
                                    className="sb-chat-icon fas fa-arrow-circle-up"
                                    onClick={() => {
                                        let message = $("#sb-chat-input").val();
                                        let productLinesClone = JSON.parse(JSON.stringify(this.state.productLines));
                                        productLinesClone[this.state.showNotes[2]][this.state.showNotes[1]]["notes"].push({message: message, context: this.getMessageHeader()});
                                        let markerChangesClone = JSON.parse(JSON.stringify(this.state.markerChanges));
                                        markerChangesClone.push({
                                            updatedMarker: productLinesClone[this.state.showNotes[2]][this.state.showNotes[1]],
                                            boardIndex: this.state.showNotes[2],
                                            rowIndex: this.state.showNotes[1],
                                        });

                                        this.setState({
                                            markerChanges: markerChangesClone,
                                            productLines: productLinesClone,
                                        }, () => {
                                            this.pushProductLineChanges();
                                        });

                                        $("#sb-chat-input").val("");
                                    }}
                                ></i>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }
                {/* Note modal */}
                {
                    this.state.showFloatingNotes ? (
                        <div id='floating-notes-container' className="stable-modal-container" style={{display: "block", position: 'absolute',}}>
                            <div style={{display:"flex", alignItems:"center",}}>
                                <div className="content-title">
                                    <i className="fas fa-comments" style={{color: "var(--deep-blue)",}}></i>
                                    <span>"{this.state.showFloatingNotes[1]}" Notes</span>
                                </div>
                            </div>
                            <div className="sb-modal-chat-container">
                                {
                                    this.state.productLines[this.state.showFloatingNotes[2]][this.state.showFloatingNotes[1]]["notes"].length > 0 ? (
                                        (this.state.productLines[this.state.showFloatingNotes[2]][this.state.showFloatingNotes[1]]["notes"] || []).reverse().slice(0, 2).map((message, index) => (
                                            <><div
                                                className="sb-chat-message"
                                                style={
                                                    index === 0 ? {
                                                        marginBottom: 0,
                                                    } : null
                                                }
                                            >{message["message"]}</div>
                                            <div className="sb-chat-header">{message["context"]}</div></>
                                        ))
                                    ) : (
                                        `There are no active messages.`
                                    )
                                }
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }
                {
                    this.state.deleting ? (
                        <div className="modal-container" style={{display: "block",}}>
                            <div style={{display:"flex", alignItems:"center",}}>
                                <div className="content-title">
                                    <i className="fas fa-trash-alt" style={{color: "var(--deep-blue)",}}></i>
                                    <span>Delete "{this.state.deleting}"</span>
                                </div>
                                <div className="modal-exit-container">
                                    <i
                                        className="fas fa-times-circle"
                                        onClick={() => {
                                            this.setState({
                                                deleting: null,
                                            });
                                            
                                        }}
                                    ></i>
                                </div>
                            </div>
                            <div>
                                Are you sure you want to delete "{this.state.deleting}"? This action cannot be undone.
                            </div>
                            <div
                                className="content-button"
                                style={{marginBottom: 0, backgroundColor: "var(--deep-blue)", marginTop: '15px', marginRight: '10px'}}
                                onClick={() => {
                                    this.setState({
                                        deleting: null,
                                    });
                                }}
                            >
                                Cancel
                            </div>
                            <div
                                className="content-button"
                                style={{marginBottom: 0, backgroundColor: "var(--ketchup-red)", marginTop: '15px',}}
                                onClick={() => {
                                    let boards = JSON.parse(localStorage.getItem('boards'));
                                    boards.splice(boards.indexOf(this.state.deleting), 1);
                                    localStorage.setItem('boards', JSON.stringify(boards));

                                    fetch(`${constants.backendAddress}/api/remove-product-line/?name=${this.state.deleting}`).then((response) => {
                                        return response.json();
                                    }).then((response) => {
                                        if (response === 200) {
                                            this.setState({
                                                deleting: false,
                                            });
                                        };
                                    });
                                }}
                            >
                                Delete
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }
                {
                    this.state.confirmCopy ? (
                        <div className="modal-container" style={{display: "block", width: '400px',}}>
                            <div style={{display:"flex", alignItems:"center",}}>
                                <div className="content-title">
                                    <i className="fas fa-copy" style={{color: "var(--deep-blue)",}}></i>
                                    <span>Copy "{this.state.confirmCopy}"</span>
                                </div>
                                <div className="modal-exit-container">
                                    <i
                                        className="fas fa-times-circle"
                                        onClick={() => {
                                            this.setState({
                                                confirmCopy: false,
                                            });
                                        }}
                                    ></i>
                                </div>
                            </div>
                            <div>
                                <div>Rename:</div>
                                <input
                                    className='content-text-input'
                                    style={{marginTop: '5px', width: '275px'}}
                                    defaultValue={this.state.confirmCopyName}
                                    id='confirm-copy-name'
                                />
                            </div>
                            <div
                                className="content-button"
                                style={{marginBottom: 0, backgroundColor: "var(--deep-blue)", marginTop: '15px', marginRight: '10px'}}
                                onClick={() => {
                                    fetch(`${constants.backendAddress}/api/copy-product-line/?name=${this.state.confirmCopy}&new_name=${$('#confirm-copy-name').val()}`).then((response) => {
                                        return response.json();
                                    }).then((response) => {
                                        if (response === 200) {
                                            this.setState({
                                                confirmCopy: false,
                                            }, () => {
                                                this.getProductLineHeaders();
                                                this.getProductLines();
                                            });
                                        };
                                    });
                                }}
                            >
                                Copy
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }
                {/* Note modal */}
                {
                    this.state.showAddTable ? (
                        <div className="modal-container" style={{display: "block",}}>
                            <div style={{display:"flex", alignItems:"center",}}>
                                <div className="content-title">
                                    <i className="fas fa-clipboard-list" style={{color: "var(--deep-blue)",}}></i>
                                    <span>{this.state.editingTable ? `Edit "${this.state.editingTable}"` : 'Add Board' }</span>
                                </div>
                                <div className="modal-exit-container">
                                    <i
                                        className="fas fa-times-circle"
                                        onClick={() => {
                                            this.setState({
                                                showAddTable: false,
                                                editingTable: false,
                                            });
                                        }}
                                    ></i>
                                </div>
                            </div>
                            <div className="content-text">
                                <span>Title:</span>
                                <input
                                    style={{fontSize: '17px', border: '1px solid #b0b0b0', color: '#808080'}}
                                    id='ab-name-input'
                                    readOnly={this.state.editingTable ? true : false}
                                ></input>
                            </div>
                            <div className='table-input'>
                                <div className='ti-title'
                                    style={{fontSize: '16px', display: 'flex', alignItems: 'center'}}
                                >
                                    Process Name:
                                    <i
                                        className='fas fa-info-circle'
                                        style={{cursor: 'pointer', marginLeft: 'auto',}}
                                        title='Please enter the name of your process. This will appear in the top row.'
                                    ></i>
                                </div>
                                <div className='ti-inner-container'>
                                    <div className='ti-scroll-container' id='header-inputs'>
                                        {
                                            this.state.editTableData['headers'].map((header, index) => (
                                                <div className='ti-row'>
                                                    <input defaultValue={header['name']}
                                                        style={{fontSize: '17px', border: '1px solid #b0b0b0', color: '#808080'}}
                                                        onChange={(event) => {
                                                            let etdCopy = {...this.state.editTableData};
                                                            etdCopy['headers'][index]['name'] = $(event.target).val();

                                                            this.setState({
                                                                editTableData: etdCopy,
                                                            });
                                                        }}
                                                    ></input>
                                                    <div className='ti-icons-container'>
                                                        <i
                                                            style={header['index'] === 0 ? {
                                                                cursor: 'default',
                                                                filter: 'brightness(1.3)',
                                                            } : null}
                                                            className='fas fa-arrow-up'
                                                            onClick={() => {
                                                                let etdCopy = {...this.state.editTableData};
                                                                let removed = etdCopy['headers'].splice(header['index'], 1);
                                                                etdCopy['headers'].splice(Math.max(0, header['index'] - 1), 0, removed[0]);

                                                                for (let index=0; index < etdCopy['headers'].length; index++) {
                                                                    etdCopy['headers'][index]['index'] = index;
                                                                };

                                                                this.setState({
                                                                    editTableData: etdCopy,
                                                                }, () => {
                                                                    $('#header-inputs').find('input').each(function(index) {
                                                                        $(this).val(etdCopy['headers'][index]['name']);
                                                                    });
                                                                });
                                                            }}
                                                        ></i>
                                                        <i
                                                            style={header['index'] === this.state.editTableData['headers'].length - 1 ? {
                                                                cursor: 'default',
                                                                filter: 'brightness(1.3)',
                                                            } : null}
                                                            className='fas fa-arrow-down'
                                                            onClick={() => {
                                                                let etdCopy = {...this.state.editTableData};
                                                                let removed = etdCopy['headers'].splice(header['index'], 1);
                                                                etdCopy['headers'].splice(header['index'] + 1, 0, removed[0]);

                                                                for (let index=0; index < etdCopy['headers'].length; index++) {
                                                                    etdCopy['headers'][index]['index'] = index;
                                                                };

                                                                this.setState({
                                                                    editTableData: etdCopy,
                                                                }, () => {
                                                                    $('#header-inputs').find('input').each(function(index) {
                                                                        $(this).val(etdCopy['headers'][index]['name']);
                                                                    });
                                                                });
                                                            }}
                                                        ></i>
                                                        <i className='fas fa-trash-alt'
                                                            onClick={() => {
                                                                let etdCopy = {...this.state.editTableData};
                                                                etdCopy['headers'].splice(header['index'], 1);

                                                                for (let index=0; index < etdCopy['headers'].length; index++) {
                                                                    etdCopy['headers'][index]['index'] = index;
                                                                };

                                                                this.setState({
                                                                    editTableData: etdCopy,
                                                                }, () => {
                                                                    $('#header-inputs').find('input').each(function(index) {
                                                                        $(this).val(etdCopy['headers'][index]['name']);
                                                                    });
                                                                });
                                                            }}
                                                        ></i>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className='ti-row' style={{marginBottom: 0,}}>
                                        <input id='ab-new-header-input'
                                            style={{fontSize: '17px', border: '1px solid #b0b0b0', color: '#808080'}}
                                        ></input>
                                        <div className='ti-icons-container'>
                                            <i
                                                style={{color: 'var(--deep-blue)', fontSize: '23px',}}
                                                className='fas fa-arrow-circle-up'
                                                onClick={() => {
                                                    let etdCopy = {...this.state.editTableData};

                                                    etdCopy['headers'].push({'name': $('#ab-new-header-input').val(),
                                                                            'index': this.state.editTableData['headers'].length,
                                                                        });

                                                    this.setState({
                                                        editTableData: etdCopy,
                                                    });

                                                    $('#ab-new-header-input').val('');
                                                }}
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='table-input'>
                                <div className='ti-title'
                                    style={{fontSize: '16px', display: 'flex', alignItems: 'center',}}
                                >
                                    Item/Job Name:
                                    <i
                                        className='fas fa-info-circle'
                                        style={{cursor: 'pointer', marginLeft: 'auto',}}
                                        title='Please enter the name of your item/job. This will appear on the leftmost column.'
                                    ></i>
                                </div>
                                <div className='ti-inner-container'>
                                    <div className='ti-scroll-container' id='row-inputs'>
                                        {
                                            this.state.editTableData['rows'].map((row, index) => (
                                                <div className='ti-row'>
                                                    <input defaultValue={row['name']}
                                                        onChange={(event) => {
                                                            let etdCopy = {...this.state.editTableData};
                                                            etdCopy['rows'][index]['name'] = $(event.target).val();

                                                            this.setState({
                                                                editTableData: etdCopy,
                                                            });
                                                        }}
                                                        style={{fontSize: '17px', border: '1px solid #b0b0b0', color: '#808080'}}
                                                    ></input>
                                                    <div className='ti-icons-container'>
                                                        <i
                                                            style={row['index'] === 0 ? {
                                                                cursor: 'default',
                                                                filter: 'brightness(1.3)',
                                                            } : null}
                                                            className='fas fa-arrow-up'
                                                            onClick={() => {
                                                                let etdCopy = {...this.state.editTableData};
                                                                let removed = etdCopy['rows'].splice(row['index'], 1);
                                                                etdCopy['rows'].splice(Math.max(0, row['index'] - 1), 0, removed[0]);

                                                                for (let index=0; index < etdCopy['rows'].length; index++) {
                                                                    etdCopy['rows'][index]['index'] = index;
                                                                };

                                                                this.setState({
                                                                    editTableData: etdCopy,
                                                                }, () => {
                                                                    $('#row-inputs').find('input').each(function(index) {
                                                                        $(this).val(etdCopy['rows'][index]['name']);
                                                                    });
                                                                });
                                                            }}
                                                        ></i>
                                                        <i
                                                            style={row['index'] === this.state.editTableData['rows'].length - 1 ? {
                                                                cursor: 'default',
                                                                filter: 'brightness(1.3)',
                                                            } : null}
                                                            className='fas fa-arrow-down'
                                                            onClick={() => {
                                                                let etdCopy = {...this.state.editTableData};
                                                                let removed = etdCopy['rows'].splice(row['index'], 1);
                                                                etdCopy['rows'].splice(row['index'] + 1, 0, removed[0]);

                                                                for (let index=0; index < etdCopy['rows'].length; index++) {
                                                                    etdCopy['rows'][index]['index'] = index;
                                                                };

                                                                this.setState({
                                                                    editTableData: etdCopy,
                                                                }, () => {
                                                                    $('#row-inputs').find('input').each(function(index) {
                                                                        $(this).val(etdCopy['rows'][index]['name']);
                                                                    });
                                                                });
                                                            }}
                                                        ></i>
                                                        <i className='fas fa-trash-alt'
                                                            onClick={() => {
                                                                let etdCopy = {...this.state.editTableData};
                                                                etdCopy['rows'].splice(row['index'], 1);

                                                                for (let index=0; index < etdCopy['rows'].length; index++) {
                                                                    etdCopy['rows'][index]['index'] = index;
                                                                };

                                                                this.setState({
                                                                    editTableData: etdCopy,
                                                                }, () => {
                                                                    $('#row-inputs').find('input').each(function(index) {
                                                                        $(this).val(etdCopy['rows'][index]['name']);
                                                                    });
                                                                });
                                                            }}
                                                        ></i>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className='ti-row' style={{marginBottom: 0,}}>
                                        <input id='ab-new-row-input'
                                            style={{fontSize: '17px', border: '1px solid #b0b0b0', color: '#808080'}}
                                        ></input>
                                        <div className='ti-icons-container'>
                                            <i
                                                style={{color: 'var(--deep-blue)', fontSize: '23px',}}
                                                className='fas fa-arrow-circle-up'
                                                onClick={() => {
                                                    let etdCopy = {...this.state.editTableData};

                                                    etdCopy['rows'].push({'name': $('#ab-new-row-input').val(),
                                                                            'index': this.state.editTableData['rows'].length,
                                                                        });

                                                    this.setState({
                                                        editTableData: etdCopy,
                                                    });

                                                    $('#ab-new-row-input').val('');
                                                }}
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id='table-input-error'>Please input all fields.</div>
                            <div>
                                <div
                                    className="content-button"
                                    style={{marginBottom: 0, marginRight: '10px'}}
                                    onClick={() => {
                                        let name = $('#ab-name-input').val();
                                        let headers = [];

                                        for (let index=0; index < this.state.editTableData['headers'].length; index++) {
                                            headers.push(this.state.editTableData['headers'][index]['name']);
                                        };

                                        let rows = [];

                                        for (let index=0; index < this.state.editTableData['rows'].length; index++) {
                                            rows.push(this.state.editTableData['rows'][index]['name']);
                                        };

                                        fetch(`${constants.backendAddress}/api/create-product-line/?name=${name}&old_name=${this.state.oldName}&rows=${JSON.stringify(rows)}&headers=${JSON.stringify(headers)}`).then((response) => {
                                            return response.json();
                                        }).then((response) => {
                                            if (response === 200) {
                                                this.setState({
                                                    showAddTable: false,
                                                    editingTable: false,
                                                }, () => {
                                                    this.getProductLineHeaders();
                                                    this.getProductLines();
                                                });
                                            } else {
                                                $('#table-input-error').css('display', 'inherit');
                                            };
                                        });
                                    }}
                                >
                                    Save
                                </div>
                                <div
                                    className="content-button"
                                    style={{marginBottom: 0, backgroundColor: "var(--ketchup-red)",}}
                                    onClick={() => {
                                        this.setState({
                                            deleting: this.state.oldName,
                                            showAddTable: false,
                                            editingTable: false,
                                        });
                                    }}
                                >
                                    Delete
                                </div>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }
                
                {
                    this.state.showSchedulePicker ? (
                        <div id='schedule-picker-container'>
                            <div id='schedule-picker'>
                                <div style={{display: 'flex'}}>
                                    <div className='content-title'>Add Tasks</div>
                                    <div style={{marginLeft: 'auto', color: '#b0b0b0', fontSize: '22px'}}>
                                        <i className='fas fa-arrow-left'
                                            title='This week'
                                            style={this.state.weekAhead ? {cursor: 'pointer', marginRight: '10px', color: 'var(--washed-out-blue)',} : {marginRight: '10px'}}
                                            onClick={() => {
                                                this.setState({
                                                    weekAhead: 0,
                                                });
                                            }}
                                        ></i>
                                        <i className='fas fa-arrow-right'
                                            title='Next week'
                                            style={!this.state.weekAhead ? {color: 'var(--washed-out-blue)', cursor: 'pointer',} : {}}
                                            onClick={() => {
                                                this.setState({
                                                    weekAhead: 6,
                                                });
                                            }}
                                        ></i>
                                    </div>
                                </div>
                                <div className="content-table-container">
                                    <table className="content-table status-boards-table process-table">
                                        <thead>
                                            <tr style={{backgroundColor: "white",}}>
                                                <th style={{textAlign: 'center', cursor: 'default',}}>Machine</th>
                                                {
                                                    this.getFutureDates(this.state.weekAhead).map((date) => (
                                                        <th style={{textAlign: 'center', cursor: 'default',}}>{date}</th>
                                                    ))
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.machines.map((machine) => (
                                                    <tr>
                                                        <td>{machine}</td>
                                                        {
                                                            new Array(6).fill(0).map((value, index) => (
                                                                <td
                                                                    style={{margin: '0', padding: '0 0 0 0', cursor: 'pointer'}}
                                                                    onClick={() => {
                                                                        let processClone = [...this.state.addProcesses];
                                                                        processClone.push({
                                                                            start_date: this.getFutureDates(this.state.weekAhead, true)[index],
                                                                            due_date: this.getFutureDates(this.state.weekAhead, true)[index],
                                                                            machine: machine,
                                                                            part: this.state.showSchedulePicker['part'],
                                                                            process: this.state.showSchedulePicker['process'],
                                                                            product_line: this.state.showSchedulePicker['productLine'],
                                                                        });

                                                                        this.setState({
                                                                            addProcesses: processClone,
                                                                        });
                                                                    }}
                                                                >
                                                                    {
                                                                        this.state.processes.map((process) => (
                                                                            <div style={{backgroundColor: 'var(--washed-out-blue)', color: 'white', fontSize: '15px', borderRadius: '3px', fontWeight: '400', margin: '5px', textAlign: 'center', opacity: 0.7,}}>
                                                                                {
                                                                                    this.dateBetween(process['start_date'], process['due_date'], `${this.getFutureDates(this.state.weekAhead)[index].split(', ')[1] + '/' + new Date().getFullYear()}`) && process['machine'] === machine ? (
                                                                                        <div
                                                                                            style={{boxSizing: 'border-box', padding: '4px',}}
                                                                                        >{`${process['part']}, ${process['process']}`}</div>
                                                                                    ) : null
                                                                                }
                                                                            </div>
                                                                        ))
                                                                    }
                                                                    {
                                                                        this.state.addProcesses.map((process, process_index) => (
                                                                            <div style={{backgroundColor: 'var(--washed-out-blue)', color: 'white', fontSize: '15px', borderRadius: '3px', fontWeight: '400', margin: '5px', textAlign: 'center',}}>
                                                                                {
                                                                                    this.dateBetween(process['start_date'], process['due_date'], `${this.getFutureDates(this.state.weekAhead)[index].split(', ')[1] + '/' + new Date().getFullYear()}`) && process['machine'] === machine ? (
                                                                                        <div
                                                                                            onClick={(event) => {
                                                                                                event.stopPropagation();
                                                                                                let processClone = [...this.state.addProcesses];
                                                                                                processClone.splice(process_index, 1);

                                                                                                this.setState({
                                                                                                    addProcesses: processClone,
                                                                                                });
                                                                                            }}
                                                                                            style={{boxSizing: 'border-box', padding: '4px',}}
                                                                                        >{`${process['part']}, ${process['process']}`}</div>
                                                                                    ) : null
                                                                                }
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{marginTop: '15px'}}>
                                    <div
                                        className="content-button"
                                        onClick={() => {
                                            this.setState({
                                                showSchedulePicker: false,
                                                addProcesses: [],
                                            });
                                            this.toggleBodyScroll();
                                            this.pushProductLineChanges();
                                            this.addNewProcesses();
                                        }}
                                        style={{fontSize: '15px', marginRight: '10px',}}
                                    >
                                        Confirm
                                    </div>
                                    <div
                                        className="content-button"
                                        onClick={() => {
                                            this.setState({
                                                showSchedulePicker: false,
                                                addProcesses: [],
                                            });
                                            this.toggleBodyScroll();
                                            this.pushProductLineChanges(true);
                                        }}
                                        style={{fontSize: '15px', marginRight: '10px', backgroundColor: '#a6a6a6'}}
                                    >
                                        Cancel
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
            </>
        );
    };
};

export default ComponentBoards;
