// Libraries
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Modules
import Login from "./screens/Login.js";
import NoMatch from "./screens/NoMatch";
import StatusBoards from "./screens/StatusBoards";
import UserManager from "./screens/UserManager";
import SBFullScreen from './screens/SBFullScreen';
import Processes from './screens/Processes';
import Join from './screens/Join';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    /**
     * Redirects user if not logged in.
     */
    verifyLoggedIn() {
        let currentURL = window.location;
        let baseURL = currentURL.protocol + "//" + currentURL.host + "/";
        let loggedIn = localStorage.getItem("loggedIn") === "true"

        if (!currentURL.href.includes('join-pierson-boards')) {
            if (!currentURL.href.includes("login") && !loggedIn) {
                window.location = "/login";
            } else if (currentURL.href === baseURL && loggedIn) {
                window.location = "/boards";
            } else if (currentURL.href === baseURL && !loggedIn) {
                window.location = "/login";
            };
        };
    };

    render() {
        return (
            <>
                {
                    this.verifyLoggedIn()
                }

                <Router>
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/boards/full-screen" element={<SBFullScreen />} />
                        <Route path="/boards" element={<StatusBoards />} />
                        <Route path="/settings" element={<UserManager />} />
                        <Route path="/join-pierson-boards" element={<Join />} />
                        <Route path="/schedule" element={<Processes />} />
                        <Route element={<NoMatch />} />
                    </Routes>
                </Router>
            </>
        );
    };
};

export default App;
