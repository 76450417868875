// Libraries
import React from "react";
import $ from "jquery";

// Modules
import { constants, } from "../constants";

import Header from "../components/Header";
import BlueBackground from "../components/BlueBackground";

class Join extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            email: null,
            shareCode: null,
            permissions: 0,
        };
    };

    componentDidMount() {
        let location = window.location.href;
        let url = new URL(location);
        let email = url.searchParams.get("email");
        let shareCode = url.searchParams.get("share_code");
        let permissions = parseInt(url.searchParams.get("permissions"));

        this.setState({
            email: email,
            shareCode: shareCode,
            permissions: permissions,
        });

        localStorage.setItem("loggedIn", "false");
        localStorage.setItem("userSettings", "{\"permissions\":0}");

        $(document).on('keypress', (event) => {
            if (event.which === 13) {
                this.createUser();
            };
        });

        document.title = "Pierson Boards – Pierson Workholding";
    };

    createUser() {
        let username = $("#username-input").val();
        let password = $("#password-input").val();
        let preferred_name = $("#preferred-name-input").val();

        fetch(`${constants.backendAddress}/api/create-user/?username=${username}&password=${password}&preferred_name=${preferred_name}&email_address=${this.state.email}&share_code=${this.state.shareCode}&permissions=${this.state.permissions}`)
            .then((response) => {
                return response.json();            
            }).then((response) => {
                window.location = "/login";
            });
    };

    render() {
        return (
            <>
                <Header />
                <BlueBackground />
                <div id="login-container" style={{width: '335px',}}>
                    <div style={{fontSize: "25px", fontWeight: "600", marginBottom: "25px",}}>Create Your Account!</div>
                    <input
                        placeholder="Username"
                        id="username-input"
                    ></input>
                    <input
                        placeholder="Password"
                        type="password"
                        id="password-input"
                    ></input>
                    <input
                        placeholder="Preferred Name"
                        id="preferred-name-input"
                    ></input>
                    <div
                        onClick={() => {
                            this.createUser();
                        }}
                        className="login-button"
                    >Submit</div>
                    {
                        this.state.errorMessage ? (
                            <div className="login-error-text">{this.state.errorMessage}</div>
                        ) : (
                            null
                        )
                    }
                    <div style={{fontSize: "14px", textAlign: "center",}}>To learn more about using Pierson Boards, please check out our <a href='#' style={{textDecoration: 'underline'}}>video guide</a>.</div>
                </div>
            </>
        );
    };
};

export default Join;
