// Libraries
import React from "react";
import $ from "jquery";

// Modules
import { constants, } from "../constants";

import Header from "../components/Header";
import BlueBackground from "../components/BlueBackground";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
        };
    };

    componentDidMount() {
        localStorage.setItem("loggedIn", "false");
        localStorage.setItem("userSettings", "{\"permissions\":0}");

        $(document).on('keypress', (event) => {
            if (event.which === 13) {
                this.verifyUser();
            };
        });

        document.title = "Login – Pierson Boards";
    };

    verifyUser() {
        let username = $("#username-input").val();
        let password = $("#password-input").val();

        fetch(`${constants.backendAddress}/api/verify-user/?username=${username}&password=${password}`)
            .then((response) => {
                return response.json();            
            }).then((response) => {
                if (typeof response["response"] === "string" ) {
                    this.setState({
                        errorMessage: response["response"],
                    });
                } else {
                    let settings = JSON.stringify(response["response"]);

                    localStorage.setItem("loggedIn", "true");
                    localStorage.setItem("userSettings", settings);
                    localStorage.setItem('themeColor', response["response"]['theme_color']);

                    this.setState({
                        errorMessage: null,
                    });

                    window.location = "/boards";
                };
            });
    };

    render() {
        return (
            <>
                <Header />
                <BlueBackground />
                <div id="login-container">
                    <div style={{fontSize: "25px", fontWeight: "600", marginBottom: "25px",}}>Welcome back!</div>
                    <input
                        placeholder="Username"
                        id="username-input"
                    ></input>
                    <input
                        placeholder="Password"
                        type="password"
                        id="password-input"
                    ></input>
                    <div
                        onClick={() => {
                            this.verifyUser();
                        }}
                        className="login-button"
                    >Submit</div>
                    {
                        this.state.errorMessage ? (
                            <div className="login-error-text">{this.state.errorMessage}</div>
                        ) : (
                            null
                        )
                    }
                    <div style={{fontSize: "14px", textAlign: "center",}}>If you do not yet have an account, please contact your account administrator.</div>
                </div>
            </>
        );
    };
};

export default Login;
