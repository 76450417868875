// Libraries
import React from "react";
import $ from "jquery";
import { Route, Switch, } from "react-router-dom";

// Modules
import "../App.css";
import {constants,} from "../constants";

import Header from "../components/Header";
import SideNavBar from "../components/SideNavBar";

// Prototype declarations
function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

/**
 * Builds the user manager page.
 */
class Processes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            processes: [],
            // processesDict: {},
            createProcess: false,
            headerHeight: 0,
            userSettings: JSON.parse(localStorage.getItem('userSettings') || '{}'),
            productLines: [],
            users: [],
            parts: [],
            productLineHeaders: {},
            searchFilter: '',
            editPermissions: false,
            editDeleteMenu: false,
            clickedProcess: null,
            editProcess: false,
            floatingNotes: false,
            // hoverNotes: false,
            // hoverNotesPos: false,
            selectedHeaders: [],
            hideHeaders: [],
            showProcesses: [],
            error: false,
            editBoard: false,
            machines: [],
            weekAhead: 0,
        };
        this.updateHeader = null;
        this.hoverTimeout = null;
    };

    componentDidMount() {
        document.title = "Schedule – Pierson Boards";

        // Check if user has "edit" permissions
        if (parseInt(this.state.userSettings['permissions']) > 1) {
            this.setState({
                editPermissions: true,
            });
        };
        
        fetch(`${constants.backendAddress}/api/get-users/?up_to=1000`).then((response) => {
            return response.json();
        }).then((response) => {
            this.setState({
                users: response,
            });
        }).then(() => {
            this.getProductLineHeaders();
            this.getProductLines();
            this.getProcesses();
            this.getMachines();
        });
        
        this.setState({
            headerHeight: $("#header-container").outerHeight(),
        });

        this.updateHeader = setInterval(() => {
            let headerHeight = $("#header-container").outerHeight();

            if (headerHeight !== this.state.headerHeight) {
                this.setState({
                    headerHeight: headerHeight,
                });
            };

            let location = window.location.href;

            if (location !== this.state.urlLocation) {
                let url = new URL(location);
                let board = url.searchParams.get("board");
                let selectedBoard = '';
                
                if (board) {
                    selectedBoard = board;
                };

                this.setState({
                    selectedBoard: selectedBoard,
                    urlLocation: location,
                });
            };
        }, 250);

        $(document).on("click", (event) => {
            let firstNavParent = ($(event.target).attr("id") || "") === "edit-menu-container";
            let secondNavParent = ($(event.target).parent().attr("id") || "") === "edit-menu-container";

            if (this.state.editDeleteMenu) {
                if (!firstNavParent && !secondNavParent) {
                    this.setState({
                        editDeleteMenu: false,
                    });
                };
            };
        });
    };

    componentWillUnmount() {
        clearInterval(this.updateHeader);
    };

    getMachines() {
        fetch(`${constants.backendAddress}/api/get-machines/`).then((response) => {
            return response.json();
        }).then((response) => {
            this.setState({
                machines: JSON.parse(response),
            });
        });
    };

    getProcesses() {
        fetch(`${constants.backendAddress}/api/get-processes/`).then((response) => {
            return response.json();
        }).then((response) => {
            // let processes = [];

            // for (let index=0; index < response.length; index++) {
            //     // let date = new Date(parseInt(response[index]['needed']));

            //     // let options = {  
            //     //     weekday: "short", month: "short",  
            //     //     day: "numeric", hour: "2-digit", minute: "2-digit"  
            //     // };  
                
            //     // let dateFormatted = date.toLocaleTimeString("en-us", options);
            //     // let assigned = JSON.parse(response[index]['assigned']) || [];
            //     // let assignedNames = [];

            //     // for (let i=0; i < assigned.length; i++) {
            //     //     for (let j=0; j < this.state.users.length; j++) {
            //     //         if (this.state.users[j]['id'].toString() === assigned[i]) {
            //     //             assignedNames.push(this.state.users[j]['preferred_name'] || this.state.users[j]['username']);
            //     //         };
            //     //     };
            //     // };

            //     let process = {
            //         // 'full': response[index],
            //         'id': response[index]['id'],
            //         'part': response[index]['part'],
            //         'product_line': response[index]['product_line'],
            //         'currentProcess': response[index]['currentProcess'],
            //         // 'needed': dateFormatted,
            //         // 'assigned': assignedNames.join(', '),
            //         'quantity': response[index]['quantity'],
            //         // 'notes': response[index]['notes'],
            //         'processes': JSON.parse(response[index]['processes']),
            //     };

            //     processes.push(process);
            // };

            // let processesDict = {};
            // for (let index=0; index < processes.length; index++) {
            //     processesDict[processes[index]['id']] = processes[index];
            // };

            this.setState({
                processes: response,
            });
        });
    };

    getUsers() {
        fetch(`${constants.backendAddress}/api/get-users/?up_to=1000`).then((response) => {
            return response.json();
        }).then((response) => {
            this.setState({
                users: response,
            });
        });
    };

    getProductLines() {
        fetch(`${constants.backendAddress}/api/get-product-lines/`).then((response) => {
            return response.json();
        }).then((response) => {
            let productLines = Object.keys(response);

            for (let index=0; index < productLines.length; index++) {
                let parsedProductLine = JSON.parse(response[productLines[index]]);
                response[productLines[index]] = parsedProductLine;
            };

            this.setState({
                productLines: response,
            });
        });
    };

    getProductLineHeaders() {
        fetch(`${constants.backendAddress}/api/get-product-line-headers/`).then((response) => {
            return response.json();
        }).then((response) => {
            let parsed = {};

            for (const [key, value] of Object.entries(response)) {
                parsed[key] = JSON.parse(value);
            };

            this.setState({
                productLineHeaders: parsed,
            });
        });
    };

    formatDate(date) {
        let dateObj = new Date(date);
        let dd = String(dateObj.getDate()).padStart(2, '0');
        let mm = String(dateObj.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = dateObj.getFullYear();

        return yyyy + '-' + mm + '-' + dd;
    }

    humanReadableDate(date) {
        let dateObj = new Date(date);
        let dd = String(dateObj.getDate()).padStart(2, '0');
        let mm = String(dateObj.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = dateObj.getFullYear();

        return mm + '/' + dd + '/' + yyyy;
    };

    getFutureDates(adder) {
        let date = new Date();
        let day = date.getDay();
        let prevMonday = new Date();
        if (date.getDay() == 0){
            prevMonday.setDate(date.getDate() - 7);
        }
        else {
            prevMonday.setDate(date.getDate() - (day-1));
        }

        let futureDates = [];
        let days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

        for (let dateAdder=0 + adder; dateAdder <= 6 + adder; dateAdder++) {
            let date = prevMonday.addDays(dateAdder);

            if (date.getDay() !== 0) {
                futureDates.push(`${days[date.getDay()]}, ${date.getMonth() + 1}/${date.getDate()}`);
                // futureDates.push(`${days[date.getDay()]}`);
            };
        };

        return futureDates;
    };

    fillIn(dayAdder, startDate, dueDate) {
        let currentDate = new Date();
        let startDateObj = new Date(startDate);
        let dueDateObj = new Date(dueDate);
        let date = currentDate.addDays(dayAdder);

        if (date >= startDateObj && date <= dueDateObj) {
            return '#ff0000';
        } else {
            return 'rgba(0, 0, 0, 0)';
        };
    };

    dateBetween(startDate, endDate, refDate) {
        let startDateObj = new Date(startDate);
        let endDateObj = new Date(endDate);
        let refDateObj = new Date(refDate);

        if (startDateObj <= refDateObj && endDateObj >= refDateObj) {
            return true;
        } else {
            return false;
        };
    };

    displayCurrentProcess(part) {
        let process;

        for (let index=0; index < part['processes'].length; index++) {
            if (part['currentProcess'] === part['processes'][index]['title']) {
                process = part['processes'][index];
            };
        };

        if (process) {
            return (
                <>
                <td style={{overflow: 'hidden', whiteSpace: 'nowrap',}}>{process['assigned']}</td>
                <td style={{backgroundColor: this.fillIn(0, process['start_date'], process['due_date'])}}></td>
                <td style={{backgroundColor: this.fillIn(1, process['start_date'], process['due_date'])}}></td>
                <td style={{backgroundColor: this.fillIn(2, process['start_date'], process['due_date'])}}></td>
                <td style={{backgroundColor: this.fillIn(3, process['start_date'], process['due_date'])}}></td>
                <td style={{backgroundColor: this.fillIn(4, process['start_date'], process['due_date'])}}></td>
                <td style={{backgroundColor: this.fillIn(5, process['start_date'], process['due_date'])}}></td>
                <td style={{backgroundColor: this.fillIn(6, process['start_date'], process['due_date'])}}></td>
                </>
            );
        };

        return (
            <>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            </>
        );
    }

    render() {
        return (
            <>
                <Header />
                {/* Background */}
                <div
                    className="location-strip-container"
                    style={{marginTop: `${this.state.headerHeight}px`,}}
                >
                    Current Page: Schedule
                </div>
                <SideNavBar />
                <div
                    id="outer-content-container"
                    style={{top: "inherit",}}
                >
                    <div>
                        <div className="content-container">
                            <div>
                                <div className="content-search-bar-container" style={{marginBottom: "20px",}}>
                                    <i className="fas fa-search" style={{fontSize: "15px",}}></i>
                                    <input
                                        placeholder="Search"
                                        style={{fontSize: "15px",}}
                                        onChange={(event) => {
                                            this.setState({
                                                searchFilter: event.target.value.toLowerCase(),
                                            });
                                        }}
                                    ></input>
                                    {/* <div
                                        id="sb-search-clear"
                                        onClick={() => {
                                            $(".content-search-bar-container input").val("");
                                            this.setState({
                                                searchFilter: "",
                                            });
                                        }}
                                    >
                                        <i className="fas fa-times"></i>
                                    </div> */}
                                </div>
                            </div>
                            {/* {
                                parseInt(this.state.userSettings["permissions"]) > 1 ? (
                                    <>
                                    <div
                                        className="content-button"
                                        onClick={() => {
                                            this.setState({
                                                createProcess: true,
                                                floatingNotes: false,
                                                error: false,
                                            }, () => {
                                                let parts = this.state.productLines[$('#create-product-line').val()];

                                                if (parts) {
                                                    parts = (Object.keys(parts) || []);
                                                } else {
                                                    parts = [];
                                                };

                                                this.setState({
                                                    parts: parts,
                                                    selectedHeaders: this.state.productLineHeaders[$('#create-product-line').val()],
                                                });
                                            });
                                        }}
                                        style={{fontSize: '15px', marginRight: '10px',}}
                                    >
                                        Add Task
                                    </div>
                                    </>
                                ) : null
                            } */}
                            <div style={{display: 'flex'}}>
                                <div className='content-title'>Tasks</div>
                                <div style={{marginLeft: 'auto', color: '#b0b0b0', fontSize: '22px'}}>
                                    <i className='fas fa-arrow-left'
                                        title='This week'
                                        style={this.state.weekAhead ? {cursor: 'pointer', marginRight: '10px', color: 'var(--washed-out-blue)',} : {marginRight: '10px'}}
                                        onClick={() => {
                                            this.setState({
                                                weekAhead: 0,
                                            });
                                        }}
                                    ></i>
                                    <i className='fas fa-arrow-right'
                                        title='Next week'
                                        style={!this.state.weekAhead ? {color: 'var(--washed-out-blue)', cursor: 'pointer',} : {}}
                                        onClick={() => {
                                            this.setState({
                                                weekAhead: 6,
                                            });
                                        }}
                                    ></i>
                                </div>
                            </div>
                            <div className="content-table-container">
                                <table className="content-table status-boards-table process-table">
                                    <thead>
                                        <tr style={{backgroundColor: "white",}}>
                                            <th style={{textAlign: 'center', cursor: 'default',}}>Machine</th>
                                            {
                                                this.getFutureDates(this.state.weekAhead).map((date) => (
                                                    <th style={{textAlign: 'center', cursor: 'default',}}>{date}</th>
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.machines.map((machine) => (
                                                <tr>
                                                    <td>{machine}</td>
                                                    {
                                                        new Array(6).fill(0).map((value, index) => (
                                                            <td style={{margin: '0', padding: '0 0 0 0'}}>
                                                                {
                                                                    this.state.processes.map((process) => (
                                                                        <div style={{backgroundColor: 'var(--deep-blue)', color: '#ffffff', fontSize: '15px', borderRadius: '3px', fontWeight: '400', cursor: 'pointer', margin: '5px', textAlign: 'center',}}>                                                       
                                                                            {
                                                                                this.dateBetween(process['start_date'], process['due_date'], `${this.getFutureDates(this.state.weekAhead)[index].split(', ')[1] + '/' + new Date().getFullYear()}`) && process['machine'] === machine ? (
                                                                                    <div
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                floatingNotes: process,
                                                                                            });
                                                                                        }}
                                                                                        style={{boxSizing: 'border-box', padding: '4px',}}
                                                                                    >{`${process['part']}, ${process['process']}`}</div>
                                                                                ) : null
                                                                            }
                                                                        </div>
                                                                    ))
                                                                }
                                                            </td>
                                                        ))
                                                    }
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    this.state.editDeleteMenu ? (
                        <div id="edit-menu-container"
                            style={{top: this.state.editDeleteMenu[1] + 19 + "px", left: this.state.editDeleteMenu[0] - 175 + 'px', width: '175px',}}
                        >
                            
                            <div
                                onClick={() => {
                                    this.setState({
                                        editProcess: true,
                                        editDeleteMenu: false,
                                        error: false,
                                    }, () => {
                                        let process;

                                        for (let index=0; index < this.state.processes.length; index++) {
                                            if ((this.state.processes[index]['id'] || 'null').toString() === this.state.clickedProcess) {
                                                process = this.state.processes[index];
                                                break;
                                            };
                                        };

                                        $('#edit-product-line').val(process['product_line']);

                                        let parts = this.state.productLines[$('#edit-product-line').val()];

                                        if (parts) {
                                            parts = (Object.keys(parts) || []);
                                        } else {
                                            parts = [];
                                        };

                                        this.setState({
                                            parts: parts,
                                            selectedHeaders: this.state.productLineHeaders[$('#edit-product-line').val()],
                                        }, () => {
                                            $('.pct-header-container').each((index, processContainer) => {
                                                let processIndex = process['processes'][index];

                                                if (!processIndex['hideHeader']) {
                                                    $(processContainer).find('.edit-assigned').val(processIndex['assigned']);
                                                    $(processContainer).find('.edit-start-date').val(new Date(processIndex['start_date']).toISOString().split('T')[0]);
                                                    $(processContainer).find('.edit-due-date').val(new Date(processIndex['due_date']).toISOString().split('T')[0]);
                                                    $(processContainer).find('.edit-notes').val(processIndex['notes']);
                                                } else {
                                                    let hideHeaders = [...this.state.hideHeaders];
                                                    hideHeaders.push(index);
                                                    this.setState({
                                                        hideHeaders: hideHeaders,
                                                    });
                                                };
                                            });
                                        });
                                        
                                        $('#edit-quantity').val(process['quantity']);
                                    });
                                }}
                            >
                                Edit
                            </div>
                            <div
                                onClick={() => {
                                    this.setState({
                                        editDeleteMenu: false,
                                    });
                                    
                                    fetch(`${constants.backendAddress}/api/delete-process/?id=${this.state.clickedProcess}`).then(() => {
                                        this.getProcesses();
                                    });
                                }}
                            >
                                Delete
                            </div>
                        </div>
                    ) : null
                }

                {
                    this.state.createProcess ? (
                        <div className="modal-container">
                            <div style={{display:"flex", alignItems:"center",}}>
                                <div className="content-title">
                                    <i className="fas fa-user-alt" style={{color: "var(--deep-blue)",}}></i>
                                    <span>Add Task</span>
                                </div>
                                <div className="modal-exit-container">
                                    <i
                                        className="fas fa-times-circle"
                                        onClick={() => {
                                            this.setState({
                                                createProcess: false,
                                            });
                                        }}
                                    ></i>
                                </div>
                            </div>
                            <div className="content-text">
                                <span>Product Line:</span>
                                <select
                                    id="create-product-line"
                                    onChange={() => {
                                        let parts = this.state.productLines[$('#create-product-line').val()];

                                        if (parts) {
                                            parts = (Object.keys(parts) || []);
                                        } else {
                                            parts = [];
                                        };

                                        this.setState({
                                            parts: parts,
                                            selectedHeaders: this.state.productLineHeaders[$('#create-product-line').val()],
                                            hideHeaders: [],
                                        });
                                    }}
                                >
                                    {
                                        JSON.parse(localStorage.getItem('boards')).map((board) => (
                                            <option>{board}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="content-text">
                                <span>Part:</span>
                                <select
                                    id='create-part'
                                >
                                    {
                                        (this.state.parts || []).map((part) => (
                                            <option>
                                                {part}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className='content-text'>
                                <span>Quantity:</span>
                                <input id='create-quantity'></input>
                            </div>
                            <div className="content-text">
                                <span>Process:</span>
                                <select
                                    id='create-process'
                                >
                                    {
                                        (this.state.selectedHeaders || []).map((header) => (
                                            <option>
                                                {header}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="content-text">
                                <span>Machine:</span>
                                <select
                                    id='create-machine'
                                >
                                    {
                                        (this.state.machines || []).map((machine) => (
                                            <option>
                                                {machine}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="content-text">
                                <span>Date:</span>
                                <input
                                    id='create-start-date'
                                    type="date"
                                ></input>
                            </div>
                            <div className="content-text">
                                <span>Notes:</span>
                                <input
                                    id='create-notes'
                                ></input>
                            </div>
                            {/* <div style={{maxHeight: '200px', overflow: 'auto', border: '1px solid #C4C4C4', borderRadius: '10px', marginBottom: '20px', overflow: 'auto',}}>
                                {
                                    this.state.selectedHeaders.map((header, index) => (
                                        <div className='pct-header-container'>
                                            {
                                                !this.state.hideHeaders.includes(index) ? (
                                                    <><div className='pm-process-banner'>
                                                        <div className='create-title'>{header}</div>
                                                        <i
                                                            style={{cursor: 'pointer', marginLeft: 'auto',}}
                                                            className="fas fa-eye-slash"
                                                            onClick={() => {
                                                                let hideHeaders = [...this.state.hideHeaders];
                                                                hideHeaders.push(index);
                                                                this.setState({
                                                                    hideHeaders: hideHeaders,
                                                                });
                                                            }}
                                                        ></i>
                                                    </div>
                                                    <div style={{marginTop: '15px',}} className='pm-property-container'>
                                                        <div>Assigned:</div>
                                                        <select className='create-assigned'>
                                                        {
                                                            this.state.users.map((user) => (
                                                                <option>{user['preferred_name'] || user['username']}</option>
                                                            ))
                                                        }
                                                        </select>
                                                    </div>
                                                    <div className='pm-property-container'>
                                                        <div>Start Date:</div>
                                                        <input
                                                            className='create-start-date'
                                                            type="date"
                                                        ></input>
                                                    </div>
                                                    <div className='pm-property-container'>
                                                        <div>Due Date:</div>
                                                        <input
                                                            className='create-due-date'
                                                            type="date"
                                                        ></input>
                                                    </div>
                                                    <div className='pm-property-container'>
                                                        <div>Notes:</div>
                                                        <input
                                                            className='create-notes'
                                                        ></input>
                                                    </div></>
                                                ) : (
                                                    <>
                                                    <div className='pm-process-banner' style={{borderBottom: '1px solid #C4C4C4',}}>
                                                        <div className='create-title'>
                                                            {header}
                                                        </div>
                                                        <i
                                                            style={{cursor: 'pointer', marginLeft: 'auto',}}
                                                            className="fas fa-eye"
                                                            onClick={() => {
                                                                let hideHeaders = [...this.state.hideHeaders];
                                                                hideHeaders.splice(hideHeaders.indexOf(index), 1);
                                                                this.setState({
                                                                    hideHeaders: hideHeaders,
                                                                });
                                                            }}
                                                        ></i>
                                                    </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                    ))
                                }
                            </div> */}
                            <div>
                                <div
                                    className="content-button"
                                    style={{marginBottom: 0,}}
                                    onClick={() => {
                                        let product_line = $('#create-product-line').val();
                                        let part = $('#create-part').val();
                                        let quantity = $('#create-quantity').val();
                                        let process = $('#create-process').val();
                                        let machine = $('#create-machine').val();
                                        let startDate = $('#create-start-date').val();
                                        let dueDate = $('#create-due-date').val();
                                        let notes = $('#create-notes').val();

                                        fetch(`${constants.backendAddress}/api/create-process/?product_line=${product_line}&part=${part}&quantity=${quantity}&process=${process}&machine=${machine}&start_date=${startDate}&due_date=${dueDate}&notes=${notes}`).then((response) => {
                                            return response.json();
                                        }).then((response) => {
                                            if (response === 200) {
                                                this.getProcesses();

                                                this.setState({
                                                    createProcess: false,
                                                    hideHeaders: [],
                                                    showProcesses: [],
                                                });
                                            } else {
                                                this.setState({
                                                    error: true,
                                                });
                                            };
                                        });
                                    }}
                                >
                                    Add Task
                                </div>
                                <div
                                    className="content-button"
                                    style={{margin: '0 0 0 10px', backgroundColor: '#A6A6A6',}}
                                    onClick={() => {
                                        this.setState({
                                            createProcess: false,
                                        });
                                    }}
                                >
                                    Cancel
                                </div>
                            </div>
                            {
                                this.state.error ? (
                                    <div className='pm-error-text'>Please input all fields</div>
                                ) : (
                                    null
                                )
                            }
                        </div>
                    ) : null
                }

                {
                    this.state.floatingNotes ? (
                        <div className="modal-container" style={{display: "block",}}>
                            <div style={{display:"flex", alignItems:"center",}}>
                                <div className="content-title">
                                    <i className="fas fa-sticky-note" style={{color: "var(--deep-blue)",}}></i>
                                    <span>"{this.state.floatingNotes['part']}" Notes</span>
                                </div>
                                <div className="modal-exit-container">
                                    <i
                                        className="fas fa-times-circle"
                                        onClick={() => {
                                            this.setState({
                                                floatingNotes: false,
                                            });
                                        }}
                                    ></i>
                                </div>
                            </div>
                            <div
                                style={{marginTop: '10px',}}
                                className='content-subtitle'
                            >
                                {'Product Line: ' + this.state.floatingNotes['product_line']}
                            </div>
                            <div
                                style={{marginTop: '10px',}}
                                className='content-subtitle'
                            >
                                {'Part: ' + this.state.floatingNotes['part']}
                            </div>
                            <div
                                style={{marginTop: '10px',}}
                                className='content-subtitle'
                            >
                                
                                {'Quantity: ' + this.state.floatingNotes['quantity']}
                            </div>
                            <div
                                style={{marginTop: '10px',}}
                                className='content-subtitle'
                            >
                                
                                {'Process: ' + this.state.floatingNotes['process']}
                            </div>
                            <div
                                style={{marginTop: '10px',}}
                                className='content-subtitle'
                            >
                                
                                {'Machine: ' + this.state.floatingNotes['machine']}
                            </div>
                            <div
                                style={{marginTop: '10px',}}
                                className='content-subtitle'
                            >
                                
                                {'Date: ' + this.humanReadableDate(this.state.floatingNotes['start_date'])}
                            </div>
                            {
                                this.state.floatingNotes['notes'] ? (
                                    <div
                                        style={{marginTop: '10px'}}
                                        className='content-subtitle'
                                    >
                                        
                                        {'Notes: ' + this.state.floatingNotes['notes']}
                                    </div>
                                ) : null
                            }
                            <div style={{display: 'flex',}}>
                                <div className='content-button' style={{marginRight: '10px', backgroundColor: 'var(--deep-blue)',}}
                                    onClick={() => {
                                        this.setState({
                                            editProcess: this.state.floatingNotes,
                                            editDeleteMenu: false,
                                            floatingNotes: false,
                                            error: false,
                                        }, () => {
                                            $('#create-product-line').val(this.state.editProcess['product_line']);
                                            
                                            let parts = this.state.productLines[$('#create-product-line').val()];

                                            if (parts) {
                                                parts = (Object.keys(parts) || []);
                                            } else {
                                                parts = [];
                                            };

                                            this.setState({
                                                parts: parts,
                                                selectedHeaders: this.state.productLineHeaders[$('#create-product-line').val()],
                                                hideHeaders: [],
                                            }, () => {
                                                $('#create-part').val(this.state.editProcess['part']);
                                                $('#create-process').val(this.state.editProcess['process']);
                                                $('#create-start-date').val(this.formatDate(this.state.editProcess['start_date']));
                                            });
                                        });
                                    }}
                                >Edit</div>
                                <div className='content-button' style={{backgroundColor: 'var(--ketchup-red)',}}
                                    onClick={() => {
                                        fetch(`${constants.backendAddress}/api/delete-process/?id=${this.state.floatingNotes['id']}`).then(() => {
                                            this.getProcesses();
                                            this.setState({
                                                floatingNotes: null,
                                            });
                                        });
                                    }}
                                >Delete</div>
                            </div>
                            <div style={{marginBottom: '-15px'}}></div>
                        </div>
                    ) : null
                }

                {
                    this.state.editProcess ? (
                        <div className="modal-container">
                            <div style={{display:"flex", alignItems:"center",}}>
                                <div className="content-title">
                                    <i className="fas fa-user-alt" style={{color: "var(--deep-blue)",}}></i>
                                    <span>Edit Task</span>
                                </div>
                                <div className="modal-exit-container">
                                    <i
                                        className="fas fa-times-circle"
                                        onClick={() => {
                                            this.setState({
                                                editProcess: false,
                                            });
                                        }}
                                    ></i>
                                </div>
                            </div>
                            <div className="content-text">
                                <span>Product Line:</span>
                                <select
                                    id="create-product-line"
                                    onChange={() => {
                                        let parts = this.state.productLines[$('#create-product-line').val()];

                                        if (parts) {
                                            parts = (Object.keys(parts) || []);
                                        } else {
                                            parts = [];
                                        };

                                        this.setState({
                                            parts: parts,
                                            selectedHeaders: this.state.productLineHeaders[$('#create-product-line').val()],
                                            hideHeaders: [],
                                        });
                                    }}
                                >
                                    {
                                        JSON.parse(localStorage.getItem('boards')).map((board) => (
                                            <option>{board}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="content-text">
                                <span>Part:</span>
                                <select
                                    id='create-part'
                                >
                                    {
                                        (this.state.parts || []).map((part) => (
                                            <option>
                                                {part}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className='content-text'>
                                <span>Quantity:</span>
                                <input
                                    id='create-quantity'
                                    defaultValue={this.state.editProcess['quantity']}
                                ></input>
                            </div>
                            <div className="content-text">
                                <span>Process:</span>
                                <select
                                    id='create-process'
                                >
                                    {
                                        (this.state.selectedHeaders || []).map((header) => (
                                            <option>
                                                {header}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="content-text">
                                <span>Machine:</span>
                                <select
                                    id='create-machine'
                                    defaultValue={this.state.editProcess['machine']}
                                >
                                    {
                                        (this.state.machines || []).map((machine) => (
                                            <option>
                                                {machine}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="content-text">
                                <span>Date:</span>
                                <input
                                    id='create-start-date'
                                    type="date"
                                ></input>
                            </div>
                            <div className="content-text">
                                <span>Notes:</span>
                                <input
                                    id='create-notes'
                                    defaultValue={this.state.editProcess['notes']}
                                ></input>
                            </div>
                            <div>
                                <div
                                    className="content-button"
                                    style={{marginBottom: 0,}}
                                    onClick={() => {
                                        let product_line = $('#create-product-line').val();
                                        let part = $('#create-part').val();
                                        let quantity = $('#create-quantity').val();
                                        let process = $('#create-process').val();
                                        let machine = $('#create-machine').val();
                                        let startDate = $('#create-start-date').val();
                                        let dueDate = $('#create-start-date').val();
                                        let notes = $('#create-notes').val();

                                        fetch(`${constants.backendAddress}/api/edit-process/?id=${this.state.editProcess.id}&product_line=${product_line}&part=${part}&quantity=${quantity}&process=${process}&machine=${machine}&start_date=${startDate}&due_date=${dueDate}&notes=${notes}`).then((response) => {
                                            return response.json();
                                        }).then((response) => {
                                            if (response === 200) {
                                                this.getProcesses();

                                                this.setState({
                                                    editProcess: false,
                                                    hideHeaders: [],
                                                    showProcesses: [],
                                                });
                                            } else {
                                                this.setState({
                                                    error: true,
                                                });
                                            };
                                        });
                                    }}
                                >
                                    Save
                                </div>
                                <div
                                    className="content-button"
                                    style={{margin: '0 0 0 10px', backgroundColor: '#A6A6A6',}}
                                    onClick={() => {
                                        this.setState({
                                            editProcess: false,
                                        });
                                    }}
                                >
                                    Cancel
                                </div>
                            </div>
                            {
                                this.state.error ? (
                                    <div className='pm-error-text'>Please input all fields</div>
                                ) : (
                                    null
                                )
                            }
                        </div>
                    ) : null
                }

                {
                    this.state.editBoard ? (
                        <div className="modal-container">
                            <div style={{display:"flex", alignItems:"center",}}>
                                <div className="content-title">
                                    <i className="fas fa-user-alt" style={{color: "var(--deep-blue)",}}></i>
                                    <span>Edit Board</span>
                                </div>
                                <div className="modal-exit-container">
                                    <i
                                        className="fas fa-times-circle"
                                        onClick={() => {
                                            this.setState({
                                                editBoard: false,
                                            });
                                        }}
                                    ></i>
                                </div>
                            </div>
                            <div style={{marginBottom: '10px',}}>Add Machine:</div>
                            <div className='content-text'>
                                <input id='machines-input'></input>
                                <i
                                    style={{color: 'var(--washed-out-blue)', fontSize: '24px', marginLeft: '10px', cursor: 'pointer',}}
                                    className='fas fa-arrow-circle-up'
                                    onClick={() => {
                                        let machines = [...this.state.machines];
                                        machines.push($('#machines-input').val());

                                        fetch(`${constants.backendAddress}/api/update-machines/?machines=${JSON.stringify(machines)}`).then((response) => {
                                            return response.json();
                                        }).then(() => {
                                            $('#machines-input').val('');

                                            this.setState({
                                                machines: machines,
                                            });
                                        });
                                    }}
                                ></i>
                            </div>
                            <div style={{marginBottom: '10px',}}>Machines:</div>
                            <div style={{maxHeight: '300px', minHeight: '75px', overflow: 'auto', border: '', borderRadius: '10px', marginBottom: '-10px', overflow: 'auto', boxSizing: 'border-box', padding: '0 5px 0 0',}}>
                                {
                                    this.state.machines.map((machine, index) => (
                                        <div style={{display: 'flex', marginBottom: '7px',}}>
                                            <div>
                                                {/* <i style={{marginRight: '7px',}} className="fas fa-industry" /> */}
                                                {machine}
                                            </div>
                                            <div style={{marginLeft: 'auto',}}>
                                                <i className="fas fa-trash"
                                                    style={{cursor: 'pointer',}}
                                                    onClick={() => {
                                                        let machines = [...this.state.machines];
                                                        let popped = machines.splice(index, 1);
                                                        
                                                        fetch(`${constants.backendAddress}/api/update-machines/?machines=${JSON.stringify(machines)}`).then((response) => {
                                                            return response.json();
                                                        }).then(() => {
                                                            $('#machines-input').val('');
                
                                                            this.setState({
                                                                machines: machines,
                                                            });
                                                        });
                                                    }}
                                                ></i>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                this.state.error ? (
                                    <div className='pm-error-text'>Please input all fields</div>
                                ) : (
                                    null
                                )
                            }
                        </div>
                    ) : null
                }

                {/* {
                    this.state.hoverNotes ? (
                        <div id='hover-notes-container' className="pos-modal-container" style={{display: "block"}}>
                            <div style={{display:"flex", alignItems:"center",}}>
                                <div className="content-title">
                                    <i className="fas fa-sticky-note" style={{color: "var(--deep-blue)",}}></i>
                                    <span>{this.state.hoverNotes['part']} Notes</span>
                                </div>
                                <div className="modal-exit-container">
                                    <i
                                        className="fas fa-times-circle"
                                        onClick={() => {
                                            this.setState({
                                                hoverNotes: false,
                                            });
                                        }}
                                    ></i>
                                </div>
                            </div>
                            <div
                                style={{marginTop: '10px',}}
                                className='content-subtitle'
                            >{this.state.hoverNotes['notes']}</div>
                        </div>
                    ) : null
                } */}
            </>
        );
    };
};

export default Processes;
