// Libraries
import React from "react";
import $ from "jquery";
import { Route, Routes, } from "react-router-dom";
import Mark from "mark.js";

// Modules
import "../App.css";
import logo from "../assets/img/logo.png";
import { constants, } from "../constants";
import FooterButton from "./FooterButton";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showHelp: false,
            showSettings: false,
            settings: {},
            showLogoutMenu: false,
            showNavMenu: false,
        };
    };

    componentDidMount() {
        // Close menus if click outside
        $(document).on("click", "body *", (event) => {
            let firstLogoutParent = ($(event.target).attr("id") || "") === "logout-menu-container";
            let secondLogoutParent = ($(event.target).parent().attr("id") || "") === "logout-menu-container";
            let firstNavParent = ($(event.target).attr("id") || "") === "nav-menu-container";
            let secondNavParent = ($(event.target).parent().attr("id") || "") === "nav-menu-container";

            if (this.state.showLogoutMenu || this.state.showNavMenu) {
                if (!firstLogoutParent && !secondLogoutParent && !firstNavParent && !secondNavParent) {
                    this.setState({
                        showLogoutMenu: false,
                        showNavMenu: false,
                    });
                };
            };
        });
    };

    logOut() {
        localStorage.setItem("userSettings", "{\"permissions\":0}");
        localStorage.setItem("loggedIn", "false");
        this.setState({
            showLogoutMenu: false,
        })
        window.location.href = "/login";
    };

    getSettings() {
        fetch(`${constants.backendAddress}/api/get-settings/`).then((response) => {
            return response.json();
        }).then((response) => {
            this.setState({
                settings: response,
            });            
        });
    };

    markKeywords() {
        let mark = new Mark(document.querySelector("body"));
        let keyword = $("#header-search-bar-container input").val();

        mark.unmark({
            done: () => {
                mark.mark(keyword, { separateWordSearch: false, });
            },
        });
    };

    render() {
        return (
            <div id="header-container">
                {/* Header */}
                <a
                    className='page-nav'
                    href="/boards"
                    style={{display: "flex", alignItems: "center",}}
                    id='header-logo-container'
                >
                    <img
                        src={logo}
                        alt="Pierson Boards logo"
                    />
                    <div>Pierson Boards</div>
                </a>
                {/* <div id="header-search-bar-container">
                    <i className="fas fa-search" style={{fontSize: "18px",}}></i>
                    <input
                        placeholder="Search..."
                        style={{fontSize: "16px",}}
                        onChange={() => {
                            this.markKeywords();
                        }}
                    ></input>
                </div> */}
                <div style={{display: "flex", alignItems: "center", marginLeft: "auto",}}>
                    <Routes>
                        <Route path="/dashboard" component={() => (
                            <div style={{borderRight: "2px solid var(--border-light-grey)", margin: "0 15px 0 0",
                                display: "flex", alignItems: "center",}}
                            >
                                <a href="/dashboard/status-bars">
                                    <i className="fas fa-home"></i>
                                </a>
                                <a href="/dashboard/process-history">
                                    <i className="fas fa-history"></i>
                                </a>
                                <a href="/dashboard/process-manager">
                                    <i className="fas fa-pencil-alt"></i>
                                </a>
                            </div>
                        )} />
                        <Route render={() => (
                            null
                        )} />
                    </Routes>
                    <i
                        className="fas fa-question-circle"
                        onClick={() => {
                            window.open('https://piersonworkholding.com/support/', '_blank').focus();
                        }}
                    ></i>
                    {
                        localStorage.getItem("loggedIn") === "true" ? (
                            <div
                                style={{display: "flex", alignItems: "center", marginRight: "14px",}}
                            >
                                <div
                                    style={{cursor: 'pointer', display: 'flex', alignItems: 'center',}}
                                    onClick={(event) => {
                                        if (this.state.showLogoutMenu) {
                                            this.setState({
                                                showLogoutMenu: false,
                                            });
                                        } else {
                                            this.setState({
                                                showLogoutMenu: true,
                                                showNavMenu: false,
                                            });
                                        };

                                        event.stopPropagation();
                                    }}
                                >
                                    <i className="fas fa-user-circle" style={{color: "var(--deep-blue)", marginRight: "7px",}}></i>
                                    {
                                        JSON.parse(localStorage.getItem("userSettings"))["preferred_name"] ? (
                                            <div
                                                style={{display: "flex", alignItems: "center", cursor: "pointer",}}
                                            >
                                                {JSON.parse(localStorage.getItem("userSettings"))["preferred_name"]}
                                            </div>
                                        ) : (
                                            <div
                                                onClick={() => {
                                                    this.logOut();
                                                }}
                                                style={{cursor: "pointer",}}
                                            >
                                                Logout
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        ) : (
                            <a href="/login" style={{display: "flex", alignItems: "center", marginRight: "5px",}}>
                                <i className="fas fa-user-circle" style={{color: "var(--deep-blue)", marginRight: "7px",}}></i>
                                Login
                            </a>
                        )
                    }
                    <div
                        onClick={(event) => {
                            if (this.state.showNavMenu) {
                                this.setState({
                                    showNavMenu: false,
                                });
                            } else {
                                this.setState({
                                    showNavMenu: true,
                                    showLogoutMenu: false,
                                });
                            };

                            event.stopPropagation();
                        }}
                    >
                        {
                            localStorage.getItem("loggedIn") === "true" ? (
                                <i
                                    id='nav-icon'
                                    className="fas fa-bars"
                                    style={{marginRight: 0,}}
                                ></i>
                            ) : ( null )
                        }
                    </div>
                    {
                        localStorage.getItem("loggedIn") === "true" ? (
                            <>
                            {
                                JSON.parse(localStorage.getItem("userSettings"))["permissions"] > 1 ? (
                                    <a
                                        href='/settings'
                                        className="page-nav"
                                    >
                                        <i
                                            id='settings-icon'
                                            className="fas fa-cog"
                                            style={{marginRight: 0,}}
                                        ></i>
                                    </a>
                                ) : null
                            }
                            </>
                        ) : null
                    }

                    {/* Logout menu */}
                    {
                        this.state.showLogoutMenu ? (
                            <div
                                id="logout-menu-container"
                                style={{top: $("#header-container").outerHeight() + "px", right: "25px",}}
                            >
                                <div
                                    onClick={() => {
                                        this.logOut();
                                    }}
                                >
                                    Logout
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }

                    {/* Navigator menu */}
                    {
                        this.state.showNavMenu ? (
                            <div
                                id="nav-menu-container"
                                style={{top: $("#header-container").outerHeight() + "px",}}
                            >
                                {/* <a href="/dashboard/status-bars"> */}
                                {/* <a href="#" onClick={(event) => {
                                    event.stopPropagation();
                                    alert("While the shop is still getting moved, the Dashboard app will be unavailable. Please check back soon! For any questions, please click the help icon.");
                                }}>
                                    <div>
                                        Dashboard
                                    </div>
                                </a> */}
                                <a
                                    className='page-nav'
                                    href="/boards"
                                >
                                    <div>
                                        Boards
                                    </div>
                                </a>
                                <a
                                    className='page-nav'
                                    href="/schedule"
                                >
                                    <div>
                                        Schedule
                                    </div>
                                </a>
                                {
                                    JSON.parse(localStorage.getItem("userSettings"))["permissions"] > 1 ? (
                                        <a
                                            href="/settings"
                                            className='page-nav'
                                        >
                                            <div>
                                                Settings
                                            </div>
                                        </a>
                                    ) : null
                                }
                            </div>
                        ) : (
                            null
                        )
                    }

                    {/* Footer button */}
                    <Routes>
                        <Route path="/login" component={() => (
                            <FooterButton
                                name="Help"
                                showModal={() => {
                                    this.setState({
                                        showHelp: true,
                                    });
                                }}
                            />
                        )} />
                        <Route path="/apps" component={() => (
                            <FooterButton
                                name="Help"
                                showModal={() => {
                                    this.setState({
                                        showHelp: true,
                                    });
                                }}
                            />
                        )} />
                        <Route render={() => (
                            null
                        )} />
                    </Routes>

                    {/* Help modal */}
                    {
                        this.state.showHelp ? (
                            <div className="modal-container">
                                <div style={{display:"flex", alignItems:"center", marginBottom: "10px",}}>
                                    <div className="modal-title">
                                        <i
                                            className="fas fa-question-circle"
                                            style={{color: "var(--deep-blue)", marginRight: "7px",}}
                                        ></i>
                                        Help
                                    </div>
                                    <i
                                        className="fas fa-times-circle"
                                        style={{color: "var(--ketchup-red)", marginLeft: "auto", marginRight: 0,}}
                                        onClick={() => {
                                            this.setState({
                                                showHelp: false,
                                                showSettings: false,
                                            });
                                        }}
                                    ></i>
                                    </div>
                                <div style={{fontSize: "18px",}}>For any problems, please contact Carlos or Chandler on Signal.</div>
                            </div>
                        ) : (
                            null
                        )
                    }
                
                    {/* Settings modal */}
                    {
                        this.state.showSettings ? (
                            <div className="modal-container" style={{display: "block",}}>
                                {
                                    JSON.parse(localStorage.getItem("userSettings"))["permissions"] > 1 ? (
                                        <>
                                            <div style={{display:"flex", alignItems:"center",}}>
                                                <div className="content-title">
                                                    <i className="fas fa-cog" style={{color: "var(--deep-blue)", marginRight: "7px",}}></i>
                                                    <span>Settings</span>
                                                </div>
                                                <div className="modal-exit-container">
                                                    <i
                                                        className="fas fa-times-circle"
                                                        style={{color: "var(--ketchup-red)", marginRight: 0,}}
                                                        onClick={() => {
                                                            this.setState({
                                                                showSettings: false,
                                                                showHelp: false,
                                                                errorMessage: null,
                                                            });
                                                        }}
                                                    ></i>
                                                </div>
                                            </div>
                                            <div className="content-text">
                                                <span>Email address:</span>
                                                <input
                                                    id="settings-email-address"
                                                    defaultValue={this.state.settings["email_address"]}
                                                ></input>
                                            </div>
                                            <div className="content-text">
                                                <span>Email password:</span>
                                                <input
                                                    id="settings-email-password"
                                                    type="password"
                                                    defaultValue={this.state.settings["email_password"]}
                                                ></input>
                                            </div>
                                            <div>
                                                <div
                                                    className="content-button"
                                                    style={{marginBottom: 0,}}
                                                    onClick={() => {
                                                        let emailAddress = $("#settings-email-address").val();
                                                        let emailPassword = $("#settings-email-password").val();

                                                        fetch(`${constants.backendAddress}/api/update-settings/?email_address=${emailAddress}&email_password=${emailPassword}`).then((response) => {
                                                            return response.json();
                                                        }).then((response) => {
                                                            if (typeof response["response"] === "string") {
                                                                this.setState({
                                                                    errorMessage: response["response"],
                                                                });
                                                            } else {
                                                                this.setState({
                                                                    showSettings: false,
                                                                    showHelp: false,
                                                                    errorMessage: null,
                                                                });

                                                                $("#settings-email-address").val("");
                                                                $("#settings-email-password").val("");
                                                            };
                                                        });
                                                    }}
                                                >
                                                    Save
                                                </div>
                                                {
                                                    this.state.errorMessage ? (
                                                        <div className="content-error-text">{this.state.errorMessage}</div>
                                                    ) : (
                                                        null
                                                    )
                                                }
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div style={{display:"flex", alignItems:"center",}}>
                                                <div className="content-title">
                                                    <i className="fas fa-cog" style={{color: "var(--deep-blue)", marginRight: "7px",}}></i>
                                                    <span>Settings</span>
                                                </div>
                                                <div className="modal-exit-container">
                                                    <i
                                                        className="fas fa-times-circle"
                                                        style={{color: "var(--ketchup-red)", marginRight: 0,}}
                                                        onClick={() => {
                                                            this.setState({
                                                                showSettings: false,
                                                                showHelp: false,
                                                                errorMessage: null,
                                                            });
                                                        }}
                                                    ></i>
                                                </div>
                                                </div>
                                            <div style={{fontSize: "18px",}}>You do not have permission to change settings.</div>
                                        </>
                                    )
                                }
                            </div>
                        ) : (
                            null
                        )
                    }
                </div>
            </div>
        );
    };
};

export default Header;
