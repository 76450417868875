// Libraries
import React from "react";
import $ from "jquery";
import { Route, Routes, } from "react-router-dom";

// Modules
import "../App.css";

import Header from "../components/Header";
import SideNavBar from "../components/SideNavBar";
import ComponentBoardsFS from "../components/ComponentBoardsFS";

class StatusBoards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            headerHeight: 0,
        };
    };

    componentDidMount() {
        document.title = "Boards – Pierson Boards";

        this.setState({
            headerHeight: $("#header-container").outerHeight(),
        });
    };

    render() {
        return (
            <>
                <div
                    style={{boxSizing: "border-box", top: "inherit",}}
                    id="fssb-outer-container"
                >
                    <ComponentBoardsFS />
                </div>
            </>
        );
    };
};

export default StatusBoards;
