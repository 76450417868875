// Libraries
import React from "react";
import $ from "jquery";
import { Route, Routes, } from "react-router-dom";

// Modules
import "../App.css";

import Header from "../components/Header";
import SideNavBar from "../components/SideNavBar";
import ComponentBoards from "../components/ComponentBoards";

class StatusBoards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            headerHeight: 0,
            urlLocation: null,
            selectedBoard: null,
            navCollapsed: false,
            editing: false,
        };
        this.updateHeader = null;
        this.componentRef = React.createRef();
        this.toggleEditing = this.toggleEditing.bind(this);
        this.showAddTable = this.showAddTable.bind(this);
        this.componentBoardRef = React.createRef();
    };

    componentDidMount() {
        document.title = "Boards – Pierson Boards";

        this.setState({
            headerHeight: $("#header-container").outerHeight(),
        });

        this.updateHeader = setInterval(() => {
            let headerHeight = $("#header-container").outerHeight();

            if (headerHeight !== this.state.headerHeight) {
                this.setState({
                    headerHeight: headerHeight,
                });
            };

            let location = window.location.href;

            if (location !== this.state.urlLocation) {
                let url = new URL(location);
                let board = url.searchParams.get("board");
                let selectedBoard = '';
                
                if (board) {
                    selectedBoard = board;
                };

                this.setState({
                    selectedBoard: selectedBoard,
                    urlLocation: location,
                });
            };
        }, 250);
    };

    componentWillUnmount() {
        clearInterval(this.updateHeader);
    };
    
    toggleEditing() {
        let value;

        if (this.state.editing) {
            this.setState({
                editing: false,
            });
            value = false;
        } else {
            this.setState({
                editing: true,
            });
            value = true;
        };

        this.componentRef.current.toggleEditing(value);
    };

    showAddTable(productLine) {
        this.componentBoardRef.current.showAddTable(productLine);
    };

    render() {
        return (
            <>
                <Header />
                <div
                    className="location-strip-container"
                    style={{marginTop: `${this.state.headerHeight}px`,}}
                >
                    <a href='/boards' style={{marginLeft: '-4px'}}>Current Page: Boards</a>
                    {
                        this.state.selectedBoard ? (
                            <><i className="fas fa-arrow-right"></i>
                            {this.state.selectedBoard}
                            </>
                        ) : null
                    }
                </div>
                <div
                    className="fixed-location-strip-container"
                    style={{top: `${this.state.headerHeight}px`, zIndex: 10,}}
                >
                    <a href='/boards' style={{marginLeft: '-4px'}}>Current Page: Boards</a>
                    {
                        this.state.selectedBoard ? (
                            <><i className="fas fa-arrow-right"></i>
                            {this.state.selectedBoard}
                            </>
                        ) : null
                    }
                </div>
                <SideNavBar
                    ref={this.componentRef}
                    showAddTable={this.showAddTable}
                />
                <div
                    style={{boxSizing: "border-box", top: "inherit",}}
                    id="outer-content-container"
                >
                    <ComponentBoards
                        ref={this.componentBoardRef}
                        onToggleEdit={this.toggleEditing}
                        headerHeight={this.state.headerHeight}
                    />
                </div>
                {/* <div id='collapse-icon' style={{backgroundColor: '#ffffff', borderRadius: '50%', height: '20px', alignItems: 'center', position: 'fixed', left: '238px', top: '250px', fontSize: '22px', color: 'var(--light-grey-2)',}}>
                    <i
                        style={{cursor: 'pointer',}}
                        className={this.state.navCollapsed ? "fas fa-arrow-circle-right" : "fas fa-arrow-circle-left"}
                        onClick={() => {
                            if (this.state.navCollapsed) {
                                $('#sb-actions-container').attr('class', 'sb-horiz');
                                // $('#sb-actions-container').css({flexDirection: 'inherit', left: '25px', boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.07)', padding: '8px 12px 8px 0'})
                                // $('#sb-actions-container i').css({margin: '0 0 0 12px',});
                                $('#collapse-icon').css('left', '238px');
                                $('#outer-content-container').css('width', 'calc(100% - 250px)');
                                this.componentRef.current.toggleSideNav();
                            } else {
                                $('#sb-actions-container').attr('class', 'sb-vert');
                                // $('#sb-actions-container').css({flexDirection: 'column', alignItems: 'center', left: '17px', boxShadow: 'none', padding: '0 0 0 0'});
                                // $('#sb-actions-container i').css({margin: '12px 0 0 0',});
                                $('#collapse-icon').css('left', '58px');
                                $('#outer-content-container').css('width', 'calc(100% - 70px)');
                                this.componentRef.current.toggleSideNav();
                            };

                            if (this.state.navCollapsed) {
                                this.setState({
                                    navCollapsed: false,
                                });
                            } else {
                                this.setState({
                                    navCollapsed: true,
                                });
                            };
                        }}
                    ></i>
                </div> */}
            </>
        );
    };
};

export default StatusBoards;
