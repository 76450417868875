// Libraries
import React from "react";

// Modules
import "../App.css";

class BlueBackground extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        return (
            <>
                {/* Background */}
                <div id="background" style={{backgroundColor: "var(--deep-blue)",}}>
                    <div id="background-filter"></div>
                </div>
            </>
        );
    };
};

export default BlueBackground;
