// Libraries
import React from "react";
import $ from "jquery";

// Modules
import "../App.css";
import { constants, } from "../constants";

class ComponentBoards extends React.Component {
    _isMounted = false;
    tableIntervals = [];

    constructor(props) {
        super(props);
        this.state = {
            productLines: null,
            productLineHeaders: {},
            headingFilter: [],
            excludeHeading: [],
            showNotes: null,
            selectedProductLine: "All",
            searchFilter: "",
            showAdvancedTools: false,
            undoCache: null,
            redoCache: null,
            showFilters: false,
            lastUpdatedMarker: null,
            showAddTable: false,
            editTableData: {'headers': [], 'rows': []},
            urlLocation: null,
            productLineOrder: [],
        };
        this.pressTimer = null;
        this.longPressed = false;
        this.visibleComponents = 0;
        this.updateCells = null;
        this.editIndex = null;
        this.changedPath = null;
    };

    componentDidMount() {
        this.setState({
            urlLocation: window.location.href,
        }, () => {
            this.changedPath = setInterval(() => {
                let location = window.location.href;

                if (location !== this.state.urlLocation) {
                    let url = new URL(location);
                    let board = url.searchParams.get("board");
                    let selectedProductLine = this.state.selectedProductLine;
                    
                    if (board) {
                        selectedProductLine = board;
                        $('#board-select').val(selectedProductLine);
                    };

                    this.setState({
                        selectedProductLine: selectedProductLine,
                        urlLocation: location,
                    });
                };
            }, 250);
        });

        this._isMounted = true;
        this.getProductLineHeaders();
        this.getProductLines();
        
        $(window).on('scroll', () => {
            let overTable = false;
            let headerHeight = $('thead').height();
            let position = $(document).scrollTop() + headerHeight;

            if (this.tableIntervals.length) {
                for (let index=0; index < this.tableIntervals.length; index++) {
                    if (this.tableIntervals[index][0] <= position &&
                        position <= this.tableIntervals[index][1]) {
                        overTable = true;
                    };
                };
            };

            if (overTable) {
                $('#static-table-header').css({display: 'flex',});
            } else {
                $('#static-table-header').css({display: 'none',});
            };
        });

        // Close modal by clicking out of it
        $(document).on("click", "body *", (event) => {
            let firstParent = ($(event.target).attr("class") || "").includes("modal-container");
            let secondParent = ($(event.target).parent().attr("class") || "").includes("modal-container");
            let thirdParent = ($(event.target).parent().parent().attr("class") || "").includes("modal-container");
            let fourthParent = ($(event.target).parent().parent().parent().attr("class") || "").includes("modal-container");

            if (this.state.showNotes) {
                if (!firstParent && !secondParent && !thirdParent && !fourthParent) {
                    this.setState({
                        showNotes: false,
                    });
                };
            };
        });

        this.updateCells = setInterval(() => {
            fetch(`${constants.backendAddress}/api/get-edit-index/`).then((response) => {
                return response.json();
            }).then((response) => {
                if (this.editIndex && parseInt(response) > this.editIndex) {
                    this.getProductLineHeaders();
                    this.getProductLines();
                    this.forceUpdate();
                    $(window).trigger('scroll');
                };

                this.editIndex = parseInt(response);
            })
        }, 15000);
    };

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.updateCells);
        clearInterval(this.changedPath);
    };

    componentDidUpdate() {
        this.displayTableHeader();
    };

    displayTableHeader() {
        let tableIntervals = [];
        
        $('table').each(function() {
            let parameters = this.getBoundingClientRect();
            tableIntervals.push([parameters['top'], parameters['bottom']]);
        });

        this.tableIntervals = tableIntervals;
    };

    getProductLines() {
        fetch(`${constants.backendAddress}/api/get-product-line-order/`).then((response) => {
            return response.json();
        }).then((response) => {
            this.setState({
                productLineOrder: JSON.parse(response),
            });
        }).then(() => {
            fetch(`${constants.backendAddress}/api/get-product-lines/`).then((response) => {
                return response.json();
            }).then((response) => {
                if (this._isMounted) {
                    let productLines = Object.keys(response);

                    for (let index=0; index < productLines.length; index++) {
                        let parsedProductLine = JSON.parse(response[productLines[index]]);
                        response[productLines[index]] = parsedProductLine;
                    };

                    let orderedProductLines = {};

                    for (let index=0; index < this.state.productLineOrder.length; index++) {
                        orderedProductLines[this.state.productLineOrder[index]] = response[this.state.productLineOrder[index]];
                    };

                    localStorage.setItem('boards', JSON.stringify(Object.keys(orderedProductLines)));

                    this.setState({
                        productLines: orderedProductLines,
                    });
                };
            });
        });
    };

    getProductLineHeaders() {
        fetch(`${constants.backendAddress}/api/get-product-line-headers/`).then((response) => {
            return response.json();
        }).then((response) => {
            if (this._isMounted) {
                let parsed = {};

                for (const [key, value] of Object.entries(response)) {
                    parsed[key] = JSON.parse(value);
                };

                this.setState({
                    productLineHeaders: parsed,
                });
            };
        });
    };

    saveProductLines(productLines=null) {
        if (this.editIndex) {
            this.editIndex += 1;
        } else {
            this.editIndex = 1;
        };

        if (productLines) {
            fetch(`${constants.backendAddress}/api/save-product-lines/?contents=${JSON.stringify(productLines)}`);
        } else {
            fetch(`${constants.backendAddress}/api/save-product-lines/?contents=${JSON.stringify(this.state.productLines)}`);
        };
    };

    updateMarker(event) {
        let xPosition = event.pageX - $(event.target).offset().left;
        let distanceToEdge = $(event.target).outerWidth() - xPosition;

        if ($(event.target).css("background-color") === "rgb(208, 208, 208)") {
            event.stopPropagation();
            return;
        } else if ($(event.target).css("background-color") === "rgb(55, 135, 255)" && distanceToEdge > 15) {
            let location = $(event.target).attr("class").split("-");
            
            this.setState({
                showNotes: location,
            });
        } else if ($(event.target).css("background-color") === "rgb(255, 0, 0)") {
            let location = $(event.target).parent().attr("class").split("-");

            this.setState({
                showNotes: location,
            });
        } else {
            // Unshow notes
            this.setState({
                showNotes: false,
            });

            if (this.state.showAdvancedTools) {
                this.setState({
                    undoCache: this.state.productLines,
                    redoCache: null,
                    lastUpdatedMarker: event.pageY - $(event.target).outerHeight() - (0.5 * $(window).height()),
                });
                
                let location = $(event.target).attr("class").split("-");
                let clonedProductLines = JSON.parse(JSON.stringify(this.state.productLines));
                clonedProductLines[location[2]][location[1]]["highlightedIndex"] = parseInt(location[0]);

                // Clear notes if "In Stock"
                if (parseInt(location[0]) === 8) {
                    clonedProductLines[location[2]][location[1]]["notes"] = [];
                };

                if (distanceToEdge <= 15) {
                    clonedProductLines[location[2]][location[1]]["splitMarker"] = true;
                } else {
                    clonedProductLines[location[2]][location[1]]["splitMarker"] = false;
                };

                this.setState({
                    productLines: clonedProductLines,
                });

                this.saveProductLines(clonedProductLines);
            };
        };

        event.stopPropagation();
    };

    greyOutCell(event) {
        if (this.state.showAdvancedTools) {
            let location = $(event.target).attr("class").split("-");
            let clonedProductLines = JSON.parse(JSON.stringify(this.state.productLines));
            
            if ($(event.target).css("background-color") === "rgb(208, 208, 208)") {
                clonedProductLines[location[2]][location[1]]["greyIndices"].splice(clonedProductLines[location[2]][location[1]]["greyIndices"].indexOf(parseInt(location[0])), 1);
            } else {
                clonedProductLines[location[2]][location[1]]["greyIndices"] = [...clonedProductLines[location[2]][location[1]]["greyIndices"], parseInt(location[0])];
            };

            this.setState({productLines: clonedProductLines,});
            this.saveProductLines(clonedProductLines);
        };        
    };

    filterByHeading(event) {
        let index = parseInt($(event.target).attr("class"));

        if (this.state.headingFilter.includes(index)) {
            let headingFilterClone = JSON.parse(JSON.stringify(this.state.headingFilter));
            headingFilterClone.splice(headingFilterClone.indexOf(index), 1)

            this.setState({
                headingFilter: headingFilterClone,
            });
        } else if (this.state.excludeHeading.includes(index)) {
            let excludeHeadingClone = JSON.parse(JSON.stringify(this.state.excludeHeading));
            excludeHeadingClone.splice(excludeHeadingClone.indexOf(index), 1)

            this.setState({
                excludeHeading: excludeHeadingClone,
            });
        } else {
            this.setState({
                headingFilter: [...this.state.headingFilter, index],
            });
        };
    };

    excludeHeading(event) {
        let index = parseInt($(event.target).attr("class"));

        if (this.state.excludeHeading.includes(index)) {
            let excludeHeadingClone = JSON.parse(JSON.stringify(this.state.excludeHeading));
            excludeHeadingClone.splice(excludeHeadingClone.indexOf(index), 1)

            this.setState({
                excludeHeading: excludeHeadingClone,
            });
        } else {
            this.setState({
                excludeHeading: [...this.state.excludeHeading, index],
            });  
        };
    };

    buildTable(productLine, index, headers, selectedProductLine=false) {
        if (!headers) {
            headers = [];
        };

        let tableContents = this.buildTableContents(productLine, headers);

        // Reset this.visisbleComponents
        if (index === 0 || selectedProductLine) {
            this.visibleComponents = 0;
        };

        const tableContainer = (
            <div style={{width: 'calc((100% - 90px) * (1/3))', marginRight: '30px', marginBottom: '60px',}}>
            <div
                className="content-title"
            >
                {
                    this.state.showAdvancedTools ? (
                        <i className="fas fa-edit"
                            style={{cursor: 'pointer',}}
                            onClick={() => {
                                let headers = [];
                                let rows = [];
                                let headers_list = this.state.productLineHeaders[productLine];
                                let rows_list = Object.keys(this.state.productLines[productLine]);

                                for (let index=0; index < headers_list.length; index++) {
                                    headers.push({
                                        'index': index,
                                        'name': headers_list[index],
                                    });
                                };

                                for (let index=0; index < rows_list.length; index++) {
                                    rows.push({
                                        'index': index,
                                        'name': rows_list[index],
                                    });
                                };

                                let editTableData = {
                                    'headers': headers,
                                    'rows': rows,
                                };

                                this.setState({
                                    editTableData: editTableData,
                                    showAddTable: true,
                                }, () => {
                                    $('#header-inputs').find('input').each(function(index) {
                                        $(this).val(editTableData['headers'][index]['name']);
                                    });

                                    $('#row-inputs').find('input').each(function(index) {
                                        $(this).val(editTableData['rows'][index]['name']);
                                    });

                                    $('#ab-name-input').val(productLine);
                                });
                            }}
                        ></i>
                    ) : (
                        <i className="fas fa-clipboard-list"></i>
                    )
                }
                {productLine}
            </div>
            <div
                id="fssb-content-table-container"
            >
                <table
                    className="content-table status-boards-table"
                    id={`sb-${productLine.toLowerCase().replace(/-/g, " ")}`}
                >
                    <thead>
                        <tr className='th-row'>
                            <th style={{cursor: "default",}}>Name</th>
                            {
                                headers.map((value, index) => (
                                    <><th
                                        className={index}
                                        onClick={(event) => {
                                            if (!this.longPressed) {
                                                this.filterByHeading(event);
                                            } else {
                                                this.longPressed = false;
                                            };
                                        }}
                                        onMouseUp={() => {
                                            clearTimeout(this.pressTimer);
                                        }}
                                        onMouseDown={(event) => {
                                            this.longPressed = false;
                                            this.pressTimer = window.setTimeout(() => {
                                                this.longPressed = true;
                                                this.excludeHeading(event);
                                            }, 500);
                                        }}
                                        style={
                                            this.state.headingFilter.includes(index) ? (
                                                {backgroundColor: "var(--washed-out-blue)", borderBottom: "1px solid var(--washed-out-blue)", color: "#ffffff",}
                                            ) : (
                                                this.state.excludeHeading.includes(index) ? (
                                                    {backgroundColor: "rgb(208, 208, 208)", color: "rgb(0, 100, 100)",}
                                                ) : (
                                                    null
                                                )
                                            )
                                        }
                                    >{value}</th></>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tableContents["contents"]
                        }
                        {
                            <tr style={{display: "none",}}><td>{
                                this.visibleComponents += tableContents["visibleComponents"]
                            }</td></tr>
                        }
                    </tbody>
                </table>
            </div>
            </div>
        );

        if (tableContents["visibleComponents"] > 0 || this.state.searchFilter === "") {
            return tableContainer;
        } else if (index + 1 === Object.keys((this.state.productLines || {})).length || selectedProductLine) {
            // Check if no visible components
            if (this.visibleComponents === 0) {
                this.setState({
                    searchFilter: "",
                });
            };
        };
    };

    buildTableContents(productLine, headers) {
        let components = this.state.productLines[productLine];
        let visibleComponents = 0;

        const contents = (
            Object.keys(components).map((component) => (
                (component || "").toLowerCase().includes(this.state.searchFilter) ? (
                    !this.state.headingFilter.length || this.state.headingFilter.includes(parseInt(components[component]["highlightedIndex"])) ? (
                        !this.state.excludeHeading.includes(parseInt(components[component]["highlightedIndex"])) ? (
                            <tr>
                                <td style={{boxSizing: "border-box", padding: "7px 10px 7px 10px", cursor: "default",}}>{component}</td>
                                {/* <td style={{boxSizing: "border-box", padding: "7px 10px 7px 10px", cursor: "default",}}>{components[component]["location"]}</td> */}
                                {
                                    [...Array(headers.length)].map((value, index) => (
                                        <>
                                            {
                                                (components[component]["greyIndices"] || []).includes(index) ? (
                                                    <td
                                                        className={`${index}-${component}-${productLine}`}
                                                        style={{textAlign: "right", backgroundColor: "#d0d0d0",}}
                                                        onMouseUp={() => {
                                                            clearTimeout(this.pressTimer);
                                                        }}
                                                        onMouseDown={(event) => {
                                                            this.pressTimer = window.setTimeout(() => {
                                                                this.greyOutCell(event);
                                                            }, 500);
                                                            this.longPressed = true;
                                                        }}
                                                    ></td>
                                                ) : (
                                                    index < 9 && components[component]["splitMarker"] && parseInt(index) === parseInt(components[component]["highlightedIndex"]) ? (
                                                        <td
                                                            className={`${index}-${component}-${productLine}`}
                                                            onClick={(event) => {
                                                                this.updateMarker(event);
                                                            }}
                                                            style={{textAlign: "right",}}
                                                        >
                                                            <div
                                                                className="sb-split-marker"
                                                                style={{cursor: "pointer",}}
                                                            ></div>
                                                        </td>
                                                    ) : (
                                                        parseInt(index) === parseInt(components[component]["highlightedIndex"]) ? (
                                                            <td
                                                                className={`${index}-${component}-${productLine}`}
                                                                style={{backgroundColor: "var(--washed-out-blue)", borderBottom: "1px solid var(--washed-out-blue)", cursor: "pointer",}}
                                                                onClick={(event) => {
                                                                    this.updateMarker(event);
                                                                }}
                                                            ></td>
                                                        ) : (
                                                            <td
                                                                className={`${index}-${component}-${productLine}`}
                                                                onClick={(event) => {
                                                                    if (!this.longPressed) {
                                                                        this.updateMarker(event);
                                                                    } else {
                                                                        this.longPressed = false;
                                                                    };
                                                                }}
                                                                style={
                                                                    this.state.showAdvancedTools ? (
                                                                        {cursor: "pointer",}
                                                                    ) : (
                                                                        null
                                                                    )
                                                                }
                                                                onMouseUp={() => {
                                                                    clearTimeout(this.pressTimer);
                                                                }}
                                                                onMouseDown={(event) => {
                                                                    this.longPressed = false;
                                                                    this.pressTimer = window.setTimeout(() => {
                                                                        this.greyOutCell(event);
                                                                        this.longPressed = true;
                                                                    }, 500);
                                                                }}
                                                            ></td>
                                                        )
                                                    )
                                                )
                                            }
                                        </>
                                    ))
                                }
                                <td style={{display: "none",}}>
                                    {
                                        visibleComponents += 1
                                    }
                                </td>
                            </tr>
                        ) : (
                            null
                        )
                    ) : (
                        null
                    )
                ) : (
                    null
                )
            ))
        );

        return {"contents": contents, "visibleComponents": visibleComponents,};
    };

    undoChanges() {
        if (this.state.undoCache) {
            this.setState({
                redoCache: this.state.productLines,
            });
            this.setState({
                productLines: this.state.undoCache,
            });
            this.setState({
                undoCache: null,
            });

            this.saveProductLines();
        };

        if (this.state.lastUpdatedMarker) {
            window.scrollTo({top: this.state.lastUpdatedMarker, behavior: "smooth",});
            // $(`.${this.state.lastUpdatedMarker}`)[0].scrollIntoView({
            //     behavior: "smooth",
            //     block: "start"
            // });
        };
    };

    redoChanges() {
        if (this.state.redoCache) {
            this.setState({
                undoCache: this.state.productLines,
            });
            this.setState({
                productLines: this.state.redoCache,
            });
            this.setState({
                redoCache: null,
            });

            this.saveProductLines();
        };

        if (this.state.lastUpdatedMarker) {
            window.scrollTo({top: this.state.lastUpdatedMarker, behavior: "smooth",});
            // $(`.${this.state.lastUpdatedMarker}`)[0].scrollIntoView({
            //     behavior: "smooth",
            //     block: "start"
            // });
        };
    };

    toggleFilters() {
        if (this.state.showFilters) {
            this.setState({
                showFilters: false,
            });
        } else {
            this.setState({
                showFilters: true,
            });
        };
    };

    /**
     * Generates the message header for a message-note.
     */
    getMessageHeader() {
        let headers = ["Low Stock", "Matieral Ordered", "Saw and Prep.", "Op. 1", "Op. 2", "Op. 3", "Op. 4", "Plating", "In Stock", "Bonus Stock",];
        let location = headers[parseInt(this.state.productLines[this.state.showNotes[2]][this.state.showNotes[1]]["highlightedIndex"])];
        let date = new Date(Date.now()).toLocaleString().split(", ")[0];
        let user = JSON.parse(localStorage.getItem("userSettings"))["preferred_name"];

        return `${user}, "${location}", ${date}`
    };

    render() {
        // Hover effect on "undo" and "redo" buttons
        $("#sb-actions-container i").on("mouseenter", (event) => {
            if ($(event.target).css("color") === "rgb(55, 135, 255)") {
                $(event.target).css("filter", "brightness(1.25)",);
            };
        });

        $("#sb-actions-container i").on("mouseleave", (event) => {
            $(event.target).css("filter", "brightness(1)",);
        });
        
        return (
            <>
                {/* <div id='static-table-header'>
                    <div>Name</div>
                    <div>Location</div>
                    <div>Low Stock</div>
                    <div>Mat. Ordered</div>
                    <div>Saw and Prep.</div>
                    <div>Op. 1</div>
                    <div>Op. 2</div>
                    <div>Op. 3</div>
                    <div>Op. 4</div>
                    <div>Plating</div>
                    <div>In Stock</div>
                    <div>Bonus Stock</div>
                </div> */}
                <div className="content-container" style={{display: 'none', paddingBottom: 0, marginBottom: "-20px", paddingBottom: 0,}}>
                    <div style={{display: 'flex',}}>
                        <div style={{width: '100%'}}>
                            <div className="content-search-bar-container" style={{marginBottom: "20px",}}>
                                <i className="fas fa-search" style={{fontSize: "15px",}}></i>
                                <input
                                    placeholder="Search"
                                    style={{fontSize: "15px",}}
                                    onChange={(event) => {
                                        this.setState({
                                            searchFilter: event.target.value.toLowerCase(),
                                        });
                                    }}
                                ></input>
                                <div
                                    id="sb-search-clear"
                                    onClick={() => {
                                        $(".content-search-bar-container input").val("");
                                        this.setState({
                                            searchFilter: "",
                                        });
                                    }}
                                >
                                    <i className="fas fa-times"></i>
                                </div>
                            </div>
                        </div>
                        <div id="sb-filter-container" style={{margin: 0, padding: 0,}}>
                            <div className="sb-filter-select" style={{margin: '0 0 0 10px'}}>
                                <select
                                    id="board-select"
                                    onChange={() => {
                                        this.setState({
                                            selectedProductLine: $("#board-select").val(),
                                        });
                                    }}
                                >
                                    <option>All</option>
                                    {
                                        Object.keys((this.state.productLines || {})).map((productLine, index) => (
                                            <option>{productLine}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="content-container"
                    style={{minHeight: "75px", paddingBottom: 0, display: 'flex', paddingRight: 0, flexWrap: 'wrap',}}
                >
                    {
                        Object.keys((this.state.productLines || {})).map((productLine, index) => (
                            this.state.selectedProductLine === "All" ? (
                                <>
                                {
                                    this.buildTable(productLine, index, this.state.productLineHeaders[productLine])
                                }
                                </>
                            ) : (
                                productLine === this.state.selectedProductLine ? (
                                    <>
                                    {
                                        this.buildTable(productLine, index, this.state.productLineHeaders[productLine], true)
                                    }
                                    </>
                                ) : (
                                    null
                                )
                            )
                        ))
                    }
                </div>

                <div id="sb-actions-container" className='sb-horiz'>
                    {
                        this.state.showAdvancedTools ? (
                            <><i
                                className="fas fa-undo"
                                onClick={() => {
                                    this.undoChanges();
                                }}
                                title='Undo'
                                style={
                                    this.state.undoCache ? (
                                        {color: "var(--washed-out-blue)",}
                                    ) : (
                                        {cursor: "default", filter: "brightness(1)",}
                                    )
                                }
                            ></i>
                            <i
                                className="fas fa-redo"
                                onClick={() => {
                                    this.redoChanges();
                                }}
                                title='Redo'
                                style={
                                    this.state.redoCache ? (
                                        {color: "var(--washed-out-blue)",}
                                    ) : (
                                        {cursor: "default", filter: "brightness(1)",}
                                    )
                                }
                            ></i></>
                        ) : (
                            null
                        )
                    }
                    {
                        this.state.showAdvancedTools ? (
                            <i
                                className="fas fa-plus-circle"
                                title='Add Board'
                                onClick={() => {
                                    this.setState({
                                        showAddTable: true,
                                        editTableData: {'headers': [], 'rows': []},
                                    }, () => {
                                        $('#ab-name-input').val('');
                                    });
                                }}
                            >
                            </i>
                        ) : null
                    }
                    <a href='/boards'>
                        <i
                            title='Compress'
                            className="fas fa-compress"
                        ></i>
                    </a>
                    {
                        !this.state.showAdvancedTools ? (
                            <i
                                className="fas fa-question-circle"
                                title='Support'
                                onClick={() => {
                                    window.open('https://piersonworkholding.com/support/', '_blank').focus();
                                }}
                                style={{marginRight: '13px',}}
                            ></i>
                        ) : null
                    }
                </div>

                {/* Note modal */}
                {
                    this.state.showNotes ? (
                        <div className="modal-container" style={{display: "block",}}>
                            <div style={{display:"flex", alignItems:"center",}}>
                                <div className="content-title">
                                    <i className="fas fa-comments" style={{color: "var(--deep-blue)",}}></i>
                                    <span>"{this.state.showNotes[1]}" Notes</span>
                                </div>
                                <div className="modal-exit-container">
                                    <i
                                        className="fas fa-times-circle"
                                        onClick={() => {
                                            this.setState({
                                                showNotes: false,
                                            });
                                        }}
                                    ></i>
                                </div>
                            </div>
                            <div className="sb-modal-chat-container">
                                {
                                    this.state.productLines[this.state.showNotes[2]][this.state.showNotes[1]]["notes"].length > 0 ? (
                                        (this.state.productLines[this.state.showNotes[2]][this.state.showNotes[1]]["notes"] || []).reverse().map((message, index) => (
                                            <><div
                                                className="sb-chat-message"
                                                style={
                                                    index === 0 ? {
                                                        marginBottom: 0,
                                                    } : null
                                                }
                                            >{message["message"]}</div>
                                            <div className="sb-chat-header">{message["context"]}</div></>
                                        ))
                                    ) : (
                                        `There are no active messages.`
                                    )
                                }
                            </div>
                            {
                                this.state.showAdvancedTools ? (
                                    <div style={{display: "flex", alignItems: "center", marginTop: "20px",}}>
                                        <div className="sb-chat-input-container">
                                            <input
                                                placeholder="Add a note..."
                                                id="sb-chat-input"
                                            />
                                        </div>
                                        <i
                                            className="sb-chat-icon fas fa-arrow-circle-up"
                                            onClick={() => {
                                                let message = $("#sb-chat-input").val();
                                                let productLinesClone = JSON.parse(JSON.stringify(this.state.productLines));
                                                productLinesClone[this.state.showNotes[2]][this.state.showNotes[1]]["notes"].push({message: message, context: this.getMessageHeader()});

                                                this.setState({
                                                    productLines: productLinesClone,
                                                });

                                                this.saveProductLines(productLinesClone);
                                                $("#sb-chat-input").val("");
                                            }}
                                        ></i>
                                    </div>
                                ) : (
                                    null
                                )
                            }
                        </div>
                    ) : (
                        null
                    )
                }
                {/* Note modal */}
                {
                    this.state.showAddTable ? (
                        <div className="modal-container" style={{display: "block",}}>
                            <div style={{display:"flex", alignItems:"center",}}>
                                <div className="content-title">
                                    <i className="fas fa-plus-square" style={{color: "var(--deep-blue)",}}></i>
                                    <span>Add Board</span>
                                </div>
                                <div className="modal-exit-container">
                                    <i
                                        className="fas fa-times-circle"
                                        onClick={() => {
                                            this.setState({
                                                showAddTable: false,
                                            });
                                        }}
                                    ></i>
                                </div>
                            </div>
                            <div className="content-text">
                                <span>Name:</span>
                                <input
                                    id='ab-name-input'
                                ></input>
                            </div>
                            <div className='table-input'>
                                <div className='ti-title'>Headers</div>
                                <div className='ti-inner-container'>
                                    <div className='ti-scroll-container' id='header-inputs'>
                                        {
                                            this.state.editTableData['headers'].map((header, index) => (
                                                <div className='ti-row'>
                                                    <input defaultValue={header['name']}
                                                        onChange={(event) => {
                                                            console.log('here');
                                                            let etdCopy = {...this.state.editTableData};
                                                            etdCopy['headers'][index]['name'] = $(event.target).val();

                                                            this.setState({
                                                                editTableData: etdCopy,
                                                            });
                                                        }}
                                                    ></input>
                                                    <div className='ti-icons-container'>
                                                        <i
                                                            style={header['index'] === 0 ? {
                                                                cursor: 'default',
                                                                filter: 'brightness(1.3)',
                                                            } : null}
                                                            className='fas fa-arrow-up'
                                                            onClick={() => {
                                                                let etdCopy = {...this.state.editTableData};
                                                                let removed = etdCopy['headers'].splice(header['index'], 1);
                                                                etdCopy['headers'].splice(Math.max(0, header['index'] - 1), 0, removed[0]);

                                                                for (let index=0; index < etdCopy['headers'].length; index++) {
                                                                    etdCopy['headers'][index]['index'] = index;
                                                                };

                                                                this.setState({
                                                                    editTableData: etdCopy,
                                                                }, () => {
                                                                    $('#header-inputs').find('input').each(function(index) {
                                                                        $(this).val(etdCopy['headers'][index]['name']);
                                                                    });
                                                                });
                                                            }}
                                                        ></i>
                                                        <i
                                                            style={header['index'] === this.state.editTableData['headers'].length - 1 ? {
                                                                cursor: 'default',
                                                                filter: 'brightness(1.3)',
                                                            } : null}
                                                            className='fas fa-arrow-down'
                                                            onClick={() => {
                                                                let etdCopy = {...this.state.editTableData};
                                                                let removed = etdCopy['headers'].splice(header['index'], 1);
                                                                etdCopy['headers'].splice(header['index'] + 1, 0, removed[0]);

                                                                for (let index=0; index < etdCopy['headers'].length; index++) {
                                                                    etdCopy['headers'][index]['index'] = index;
                                                                };

                                                                this.setState({
                                                                    editTableData: etdCopy,
                                                                }, () => {
                                                                    $('#header-inputs').find('input').each(function(index) {
                                                                        $(this).val(etdCopy['headers'][index]['name']);
                                                                    });
                                                                });
                                                            }}
                                                        ></i>
                                                        <i className='fas fa-trash-alt'
                                                            onClick={() => {
                                                                let etdCopy = {...this.state.editTableData};
                                                                etdCopy['headers'].splice(header['index'], 1);

                                                                for (let index=0; index < etdCopy['headers'].length; index++) {
                                                                    etdCopy['headers'][index]['index'] = index;
                                                                };

                                                                this.setState({
                                                                    editTableData: etdCopy,
                                                                }, () => {
                                                                    $('#header-inputs').find('input').each(function(index) {
                                                                        $(this).val(etdCopy['headers'][index]['name']);
                                                                    });
                                                                });
                                                            }}
                                                        ></i>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className='ti-row' style={{marginBottom: 0,}}>
                                        <input id='ab-new-header-input'></input>
                                        <div className='ti-icons-container'>
                                            <i
                                                style={{color: 'var(--deep-blue)', fontSize: '22px',}}
                                                className='fas fa-arrow-circle-up'
                                                onClick={() => {
                                                    let etdCopy = {...this.state.editTableData};

                                                    etdCopy['headers'].push({'name': $('#ab-new-header-input').val(),
                                                                            'index': this.state.editTableData['headers'].length,
                                                                        });

                                                    this.setState({
                                                        editTableData: etdCopy,
                                                    });

                                                    $('#ab-new-header-input').val('');
                                                }}
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='table-input'>
                                <div className='ti-title'>Rows</div>
                                <div className='ti-inner-container'>
                                    <div className='ti-scroll-container' id='row-inputs'>
                                        {
                                            this.state.editTableData['rows'].map((row, index) => (
                                                <div className='ti-row'>
                                                    <input defaultValue={row['name']}
                                                        onChange={(event) => {
                                                            let etdCopy = {...this.state.editTableData};
                                                            etdCopy['rows'][index]['name'] = $(event.target).val();

                                                            this.setState({
                                                                editTableData: etdCopy,
                                                            });
                                                        }}
                                                    ></input>
                                                    <div className='ti-icons-container'>
                                                        <i
                                                            style={row['index'] === 0 ? {
                                                                cursor: 'default',
                                                                filter: 'brightness(1.3)',
                                                            } : null}
                                                            className='fas fa-arrow-up'
                                                            onClick={() => {
                                                                let etdCopy = {...this.state.editTableData};
                                                                let removed = etdCopy['rows'].splice(row['index'], 1);
                                                                etdCopy['rows'].splice(Math.max(0, row['index'] - 1), 0, removed[0]);

                                                                for (let index=0; index < etdCopy['rows'].length; index++) {
                                                                    etdCopy['rows'][index]['index'] = index;
                                                                };

                                                                this.setState({
                                                                    editTableData: etdCopy,
                                                                }, () => {
                                                                    $('#row-inputs').find('input').each(function(index) {
                                                                        $(this).val(etdCopy['rows'][index]['name']);
                                                                    });
                                                                });
                                                            }}
                                                        ></i>
                                                        <i
                                                            style={row['index'] === this.state.editTableData['rows'].length - 1 ? {
                                                                cursor: 'default',
                                                                filter: 'brightness(1.3)',
                                                            } : null}
                                                            className='fas fa-arrow-down'
                                                            onClick={() => {
                                                                let etdCopy = {...this.state.editTableData};
                                                                let removed = etdCopy['rows'].splice(row['index'], 1);
                                                                etdCopy['rows'].splice(row['index'] + 1, 0, removed[0]);

                                                                for (let index=0; index < etdCopy['rows'].length; index++) {
                                                                    etdCopy['rows'][index]['index'] = index;
                                                                };

                                                                this.setState({
                                                                    editTableData: etdCopy,
                                                                }, () => {
                                                                    $('#row-inputs').find('input').each(function(index) {
                                                                        $(this).val(etdCopy['rows'][index]['name']);
                                                                    });
                                                                });
                                                            }}
                                                        ></i>
                                                        <i className='fas fa-trash-alt'
                                                            onClick={() => {
                                                                let etdCopy = {...this.state.editTableData};
                                                                etdCopy['rows'].splice(row['index'], 1);

                                                                for (let index=0; index < etdCopy['rows'].length; index++) {
                                                                    etdCopy['rows'][index]['index'] = index;
                                                                };

                                                                this.setState({
                                                                    editTableData: etdCopy,
                                                                }, () => {
                                                                    $('#row-inputs').find('input').each(function(index) {
                                                                        $(this).val(etdCopy['rows'][index]['name']);
                                                                    });
                                                                });
                                                            }}
                                                        ></i>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className='ti-row' style={{marginBottom: 0,}}>
                                        <input id='ab-new-row-input'></input>
                                        <div className='ti-icons-container'>
                                            <i
                                                style={{color: 'var(--deep-blue)', fontSize: '22px',}}
                                                className='fas fa-arrow-circle-up'
                                                onClick={() => {
                                                    let etdCopy = {...this.state.editTableData};

                                                    etdCopy['rows'].push({'name': $('#ab-new-row-input').val(),
                                                                            'index': this.state.editTableData['rows'].length,
                                                                        });

                                                    this.setState({
                                                        editTableData: etdCopy,
                                                    });

                                                    $('#ab-new-row-input').val('');
                                                }}
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div
                                    className="content-button"
                                    style={{marginBottom: 0, marginRight: '10px'}}
                                    onClick={() => {
                                        let name = $('#ab-name-input').val();
                                        let headers = [];

                                        for (let index=0; index < this.state.editTableData['headers'].length; index++) {
                                            headers.push(this.state.editTableData['headers'][index]['name']);
                                        };

                                        let rows = [];

                                        for (let index=0; index < this.state.editTableData['rows'].length; index++) {
                                            rows.push(this.state.editTableData['rows'][index]['name']);
                                        };

                                        fetch(`${constants.backendAddress}/api/create-product-line/?name=${name}&rows=${JSON.stringify(rows)}&headers=${JSON.stringify(headers)}`).then((response) => {
                                            return response.json();
                                        }).then((response) => {
                                            if (response === 200) {
                                                this.setState({
                                                    showAddTable: false,
                                                });
                                            };
                                        });
                                    }}
                                >
                                    Save
                                </div>
                                <div
                                    className="content-button"
                                    style={{marginBottom: 0, backgroundColor: "var(--ketchup-red)",}}
                                    onClick={() => {
                                        fetch(`${constants.backendAddress}/api/remove-product-line/?${$('#ab-name-input').val()}`).then((response) => {
                                            return response.json();
                                        }).then((response) => {
                                            if (response === 200) {
                                                this.setState({
                                                    showAddTable: false,
                                                });
                                            };
                                        });
                                    }}
                                >
                                    Delete
                                </div>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }
            </>
        );
    };
};

export default ComponentBoards;
