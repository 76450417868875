// Libraries
import React from "react";

// Modules
import "../App.css";

import Header from "../components/Header";
import BlueBackground from "../components/BlueBackground";

/**
 * Displays if URL is not known.
 */
class NoMatch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    componentDidMount() {
        document.title = "Error 404 – Pierson Boards";
    };

    render() {
        return (
            <>
                <Header />
                <BlueBackground />
                <div id="no-match-text">
                    <i class="fas fa-exclamation-circle"></i>
                    Page not found.
                </div>
            </>
        );
    };
};

export default NoMatch;
