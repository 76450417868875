// Libraries
import React from "react";
import { NavLink, Route, Routes, } from "react-router-dom";
import $ from "jquery";

// Modules
import "../App.css";
import { constants, } from "../constants";

class SideNavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            boards: '[]',
            collapsed: true,
            editing: false,
            allNavBoards: false,
        };
        this.getBoardsInterval = null;
    }

    positionVertically() {
        let visibleHeight = $(".location-strip-container").outerHeight() - $(document).scrollTop();
        if (visibleHeight >= 0) {
            $("#side-nav").css("padding-top", visibleHeight + 30);
        } else {
            $("#side-nav").css("padding-top", 30);
        };
    };

    componentDidMount() {
        this.getBoardsInterval = setInterval(() => {
            let updatedBoards = localStorage.getItem('boards');

            if (updatedBoards !== this.state.boards) {
                this.setState({
                    boards: updatedBoards,
                });
            };
        }, 250);

        let updatedBoards = localStorage.getItem('boards');

        if (updatedBoards !== this.state.boards) {
            this.setState({
                boards: updatedBoards,
            });
        };

        $(document).scroll(() => {
            this.positionVertically();
        });

        this.positionVertically();
    };

    componentWillUnmount() {
        clearInterval(this.getBoardsInterval);
    };

    toggleSideNav() {
        if (this.state.collapsed) {
            this.setState({
                collapsed: false,
            });
        } else {
            this.setState({
                collapsed: true,
            });
        };
    };

    toggleEditing(value) {
        this.setState({
            editing: value,
        });
    };

    render() {
        return (
            <>{
                !this.state.collapsed ? (
                <div
                    id="side-nav"
                    style={{
                        top: $("#header-container").outerHeight() + "px",
                        height: $("document").outerHeight() + "px",
                    }}
                >
                    {/* <div
                        className="side-nav-title"
                        style={{fontSize: '18px'}}
                    >
                        <a href='/processes'>
                            <i style={{color: 'var(--blue-grey)',}} className="fas fa-address-card"></i>
                            <span>Apps</span>
                        </a>
                    </div> */}
                    {/* <div
                        className="side-nav-title"
                        style={{marginTop: '10px', fontSize: '18px'}}
                    >
                        <a href='/boards'>
                            <i style={{color: 'var(--blue-grey)',}} className="fas fa-clipboard-list"></i>
                            <span>Boards</span>
                        </a>
                        <i style={{color: 'var(--blue-grey)', marginLeft: '7px', cursor: 'pointer',}} className={this.state.allNavBoards ? "fas fa-caret-up": "fas fa-caret-down"}
                            onClick={() => {
                                if (this.state.allNavBoards) {
                                    this.setState({
                                        allNavBoards: false,
                                    });
                                } else {
                                    this.setState({
                                        allNavBoards: true,
                                    });
                                }
                            }}
                        ></i>
                    </div> */}
                    {/* Get all boards through function */}
                    {
                        this.state.allNavBoards ? (
                            <>
                            {
                                (JSON.parse(this.state.boards) || []).map((value) => (
                                    <div style={{position: 'relative', left: '7px', display: 'flex'}}><a
                                        href={`/boards/?board=${value}`}
                                    >
                                        <div className="side-nav-link">
                                            <span>{value}</span>
                                        </div>
                                    </a>
                                    {
                                        this.state.editing ? (
                                            <><i className="fas fa-arrow-up" style={{color: 'var(--deep-blue)', marginLeft: 'auto', cursor: 'pointer',}}
                                                onClick={() => {
                                                    let names = JSON.parse(this.state.boards);
                                                    let currentIndex;
                                                    
                                                    for (let i = names.length; i--;) {
                                                        if (names[i] === value) {
                                                            currentIndex = i;
                                                            names.splice(i, 1);
                                                        };
                                                    };

                                                    names.splice(Math.max(currentIndex - 1, 0), 0, value);
                                                    this.setState({
                                                        boards: JSON.stringify(names),
                                                    });

                                                    localStorage.setItem('boards', JSON.stringify(names));

                                                    fetch(`${constants.backendAddress}/api/update-product-line-order/?order=${JSON.stringify(names)}`).then(() => {
                                                        localStorage.setItem('orderUpdates', parseInt((localStorage.getItem('orderUpdates') || '0')) + 1);
                                                    });
                                                }}
                                            ></i>
                                            <i className="fas fa-arrow-down" style={{color: 'var(--deep-blue)', marginLeft: '7px', marginRight: '25px', cursor: 'pointer',}}
                                                onClick={() => {
                                                    let names = JSON.parse(this.state.boards);
                                                    let namesLength = names.length;
                                                    let currentIndex;
                                                    
                                                    for (let i = names.length; i--;) {
                                                        if (names[i] === value) {
                                                            currentIndex = i;
                                                            names.splice(i, 1);
                                                        };
                                                    };

                                                    names.splice(Math.min(currentIndex + 1, namesLength - 1), 0, value);
                                                    this.setState({
                                                        boards: JSON.stringify(names),
                                                    });

                                                    localStorage.setItem('boards', JSON.stringify(names));

                                                    fetch(`${constants.backendAddress}/api/update-product-line-order/?order=${JSON.stringify(names)}`).then(() => {
                                                        localStorage.setItem('orderUpdates', parseInt((localStorage.getItem('orderUpdates') || '0')) + 1);
                                                    });
                                                }}
                                            ></i></>
                                        ) : null
                                    }</div>
                                ))
                            }
                            </>
                        ) : null
                        }
                        <div
                            className="side-nav-title"
                            style={{marginTop: '10px', fontSize: '18px'}}
                        >
                            <a href='/schedule'>
                                <i style={{color: 'var(--blue-grey)',}} className="fas fa-address-card"></i>
                                <span>Schedule</span>
                            </a>
                        </div>
                    </div>
                    ) : (
                        <div
                            id="side-nav-collapsed"
                            style={{
                                top: $("#header-container").outerHeight() + "px",
                                height: $("document").outerHeight() + "px",
                            }}
                        >
                        <a className='page-nav' href='/boards' style={{marginTop: `${$(".location-strip-container").outerHeight() + 25}px`,}}>
                            <i
                                className="fas fa-clipboard-list"
                                style={{color: 'var(--blue-grey)',}}
                                title='Boards'
                            ></i>
                        </a>
                        <a href='/schedule' className='page-nav'>
                            <i
                                className="fas fa-calendar"
                                style={{color: 'var(--blue-grey)', marginTop: '12px', fontSize: '30px'}}
                                title='Schedule'
                            ></i>
                        </a>
                    </div>
                    )
                }</>
        );
    };
};

export default SideNavBar;
