// Libraries
import React from "react";
import $ from "jquery";
import { Route, Switch, } from "react-router-dom";

// Modules
import "../App.css";
import {constants,} from "../constants";

import Header from "../components//Header";
import SideNavBar from "../components/SideNavBar";

String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};

/**
 * Builds the user manager page.
 */
class UserManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            createUser: "none",
            users: [],
            errorMessage: null,
            editUser: null,
            headerHeight: 0,
            inviteEmail: false,
            userSettings: JSON.parse(localStorage.getItem('userSettings') || '{}'),
            machines: [],
            createMachine: false,
        };
        this.updateHeader = null;
    };

    componentDidMount() {
        document.title = "Settings – Pierson Workholding";

        this.getUsers();
        this.getMachines();
        // To-do: Add machines to "Settings" page
        this.setState({
            headerHeight: $("#header-container").outerHeight(),
        });

        this.updateHeader = setInterval(() => {
            let headerHeight = $("#header-container").outerHeight();

            if (headerHeight !== this.state.headerHeight) {
                this.setState({
                    headerHeight: headerHeight,
                });
            };

            let location = window.location.href;

            if (location !== this.state.urlLocation) {
                let url = new URL(location);
                let board = url.searchParams.get("board");
                console.log(board);
                let selectedBoard = '';
                
                if (board) {
                    selectedBoard = board;
                };

                this.setState({
                    selectedBoard: selectedBoard,
                    urlLocation: location,
                });
            };
        }, 250);
    };

    componentWillUnmount() {
        clearInterval(this.updateHeader);
    };

    getMachines() {
        fetch(`${constants.backendAddress}/api/get-machines/`).then((response) => {
            return response.json();
        }).then((response) => {
            this.setState({
                machines: JSON.parse(response),
            });
        });
    };

    getUsers() {
        fetch(`${constants.backendAddress}/api/get-users/?up_to=1000`).then((response) => {
            return response.json();
        }).then((response) => {
            this.setState({
                users: response,
            });
        });
    };

    editUser(event) {
        let username = $(event.target.parentNode).find("td")[0].innerHTML;

        for (let index=0; index < this.state.users.length; index++) {
            if (username === this.state.users[index]["username"]) {
                this.setState({
                    editUser: this.state.users[index],
                });
            };
        };
    };

    levelToTitle(level) {
        if (level === 0) {
            return "View Only";
        } else if (level === 1) {
            return "Edit";
        } else if (level === 2) {
            return "Admin";
        } else {
            return "";
        };
    };

    validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    render() {
        return (
            <>
                <Header />
                {/* Background */}
                {/* <div 
                    style={{height: "305px", backgroundColor: "var(--deep-blue)",}}
                ></div> */}
                <div
                    className="location-strip-container"
                    style={{marginTop: `${this.state.headerHeight}px`,}}
                >
                    Current Page: Settings
                </div>
                <SideNavBar />
                <div
                    id="outer-content-container"
                    style={{top: "inherit",}}
                >
                    <><div>
                        <div className="content-container">
                            <div className="content-title">
                                {/* <i style={{color: 'var(--blue-grey)',}} className="fas fa-users"></i> */}
                                <span style={{fontSize: '18px',}}>Manage Users</span>
                            </div>
                            <div className="content-subtitle"
                                style={{fontSize: '18px',}}
                            >
                                <span>Create and manage users. Click to edit.</span>
                            </div>
                            <div
                                className="content-button"
                                onClick={() => {
                                    this.setState({
                                        createUser: "block",
                                    });
                                }}
                                style={{fontSize: '15px'}}
                            >
                                Add User
                            </div>
                            <div
                                className="content-button"
                                style={{fontSize: '15px', marginLeft: '10px', backgroundColor: '#0bb548'}}
                                onClick={() => {
                                    alert('This feature has been disabled. Please contact Chandler for help.');
                                    // this.setState({
                                    //     inviteEmail: true,
                                    // });
                                }}
                            >
                                Invite by Email
                            </div>
                            <div className="content-table-container">
                                <table className="content-table settings-table">
                                    <thead>
                                        <tr style={{backgroundColor: "white",}}>
                                            <th>Username</th>
                                            <th>Email</th>
                                            <th>Permissions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.users.reverse().map((user) => (
                                                <tr
                                                    onClick={(event) => {
                                                        this.editUser(event);
                                                    }}
                                                >
                                                    <td>{user["username"]}</td>
                                                    <td>{user["email_address"]}</td>
                                                    <td>{this.levelToTitle(parseInt(user["permissions"]))}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="content-title" style={{marginTop: '20px'}}>
                                {/* <i style={{color: 'var(--blue-grey)',}} className="fas fa-users"></i> */}
                                <span style={{fontSize: '18px',}}>Edit Machines</span>
                            </div>
                            <div
                                className="content-button"
                                onClick={() => {
                                    this.setState({
                                        createMachine: true,
                                    });
                                }}
                                style={{fontSize: '15px'}}
                            >
                                Add Machine
                            </div>
                            <div className="content-table-container">
                                <table className="content-table settings-table">
                                    <thead>
                                        <tr style={{backgroundColor: "white",}}>
                                            <th>Name</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.machines.map((machine, index) => (
                                                <tr style={{cursor: 'default',}}>
                                                    <td style={{borderRight: 'none', cursor: 'default'}}>{machine}</td>
                                                    <td style={{textAlign: 'right', cursor: 'default', boxSizing: 'border-box', paddingRight: '25px', color: '#a9a9a9'}}>
                                                        <i className="fas fa-arrow-up"
                                                            style={{cursor: 'pointer', marginRight: '5px'}}
                                                            onClick={() => {
                                                                let machines = [...this.state.machines];
                                                                machines.splice(index, 1);
                                                                machines.splice(index - 1, 0, machine)
                                                                fetch(`${constants.backendAddress}/api/create-machine/?machines=${JSON.stringify(machines)}`).then(() => {
                                                                    this.getMachines();
                                                                });
                                                            }}
                                                        ></i>
                                                        <i className="fas fa-arrow-down"
                                                            style={{cursor: 'pointer', marginRight: '10px'}}
                                                            onClick={() => {
                                                                let machines = [...this.state.machines];
                                                                machines.splice(index, 1);
                                                                machines.splice(index + 1, 0, machine)
                                                                fetch(`${constants.backendAddress}/api/create-machine/?machines=${JSON.stringify(machines)}`).then(() => {
                                                                    this.getMachines();
                                                                });
                                                            }}
                                                        ></i>
                                                        <i className="fas fa-trash"
                                                            style={{cursor: 'pointer'}}
                                                            onClick={() => {
                                                                let machines = [...this.state.machines];
                                                                machines.splice(index, 1);
                                                                fetch(`${constants.backendAddress}/api/create-machine/?machines=${JSON.stringify(machines)}`).then(() => {
                                                                    this.getMachines();
                                                                });
                                                            }}
                                                        ></i>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {/* <div className="content-title" style={{marginTop: '25px',}}>
                                <span style={{fontSize: '18px',}}>Color Picker</span>
                            </div>
                            <div className="content-subtitle"
                                style={{fontSize: '18px',}}
                            >
                                <span>Customize the appearance of Pierson Boards with your company colors.</span>
                            </div>
                            <input
                                type="color"
                                defaultValue={sessionStorage.getItem('themeColor')}
                                id='settings-color-input'
                            />
                            <br />
                            <div
                                className='content-button'
                                style={{marginTop: '15px',}}
                                onClick={() => {
                                    let newColor = $('#settings-color-input').val();
                                    sessionStorage.setItem('themeColor', newColor);
                                    $('.location-strip-container').css('background-color', newColor);
                                    newColor = newColor.replaceAll('#', '');

                                    fetch(`${constants.backendAddress}/api/update-color/?company=${JSON.parse(sessionStorage.getItem('userSettings'))['company_account']}&color=${newColor}`);
                                }}
                            >
                                Save
                            </div> */}
                        </div>
                    </div>

                    {
                        this.state.editUser ? (
                            <div className="modal-container" style={{display: "block",}}>
                                <div style={{display:"flex", alignItems:"center",}}>
                                    <div className="content-title">
                                        <i className="fas fa-edit" style={{color: "var(--deep-blue)",}}></i>
                                        <span>Edit {this.state.editUser["username"]}</span>
                                    </div>
                                    <div className="modal-exit-container">
                                        <i
                                            className="fas fa-times-circle"
                                            onClick={() => {
                                                $("#edit-username").val("");
                                                $("#edit-password").val("");
                                                $("#edit-email-address").val("");
                                                $("#edit-permissions").val("");
                                                $("#edit-preferred-name").val("");

                                                this.setState({
                                                    editUser: null,
                                                });
                                            }}
                                        ></i>
                                    </div>
                                </div>
                                <div className="content-text">
                                    <span>Username:</span>
                                    <input
                                        id="edit-username"
                                        defaultValue={this.state.editUser["username"]}
                                        readOnly={true}
                                    ></input>
                                </div>
                                <div className="content-text">
                                    <span>Password:</span>
                                    <input
                                        id="edit-password"
                                        type="password"
                                        defaultValue={this.state.editUser["password"]}
                                    ></input>
                                </div>
                                <div className="content-text">
                                    <span>Preferred name:</span>
                                    <input
                                        id="edit-preferred-name"
                                        defaultValue={this.state.editUser["preferred_name"]}
                                    ></input>
                                </div>
                                <div className="content-text">
                                    <span>Email address:</span>
                                    <input
                                        id="edit-email-address"
                                        defaultValue={this.state.editUser["email_address"]}
                                    ></input>
                                </div>
                                <div className="content-text">
                                    <span>Permissions:</span>
                                    <select 
                                        id="edit-permissions"
                                        defaultValue={this.levelToTitle(parseInt(this.state.editUser["permissions"]))}
                                    >
                                        <option>View Only</option>
                                        <option>Edit</option>
                                        <option>Admin</option>
                                    </select>
                                </div>
                                <div>
                                    <div
                                        className="content-button"
                                        style={{marginBottom: 0, marginRight: "10px",}}
                                        onClick={() => {
                                            let username = $("#edit-username").val();
                                            let password = $("#edit-password").val();
                                            let permissions = $("#edit-permissions").val();
                                            let preferredName = $("#edit-preferred-name").val();

                                            if (permissions === "View Only") {
                                                permissions = 0;
                                            } else if (permissions === "Edit") {
                                                permissions = 1;
                                            } else if (permissions === "Admin") {
                                                permissions = 2;
                                            } else {
                                                permissions = 0;
                                            };

                                            let emailAddress = $("#edit-email-address").val();

                                            fetch(`${constants.backendAddress}/api/edit-user/?username=${username}&password=${password}&permissions=${permissions}
                                                &email_address=${emailAddress}&preferred_name=${preferredName}`).then((response) => {
                                                return response.json();
                                            }).then((response) => {
                                                if (typeof response["response"] === "string") {
                                                    this.setState({
                                                        errorMessage: response["response"],
                                                    });
                                                } else {
                                                    $("#edit-username").val("");
                                                    $("#edit-password").val("");
                                                    $("#edit-permissions").val("");
                                                    $("#edit-email-address").val("");
                                                    $("#edit-preferred-name").val("");

                                                    this.setState({
                                                        editUser: null,
                                                        errorMessage: null,
                                                    });

                                                    this.getUsers();
                                                };
                                            });
                                        }}
                                    >
                                        Save
                                    </div>
                                    <div
                                        className="content-button"
                                        style={{marginBottom: 0, backgroundColor: "var(--ketchup-red)",}}
                                        onClick={() => {
                                            let username = this.state.editUser["username"];

                                            fetch(`${constants.backendAddress}/api/delete-user/?username=${username}`).then((response) => {
                                                return response.json();
                                            }).then((response) => {
                                                if (typeof response["response"] === "string") {
                                                    this.setState({
                                                        errorMessage: response["response"],
                                                    });
                                                } else {
                                                    this.setState({
                                                        editUser: null,
                                                        errorMessage: null,
                                                    });

                                                    this.getUsers();
                                                };
                                            });
                                        }}
                                    >
                                        Delete
                                    </div>
                                </div>
                                {
                                    this.state.errorMessage ? (
                                        <div className="content-error-text">{this.state.errorMessage}</div>
                                    ) : (
                                        null
                                    )
                                }
                            </div>
                        ) : (
                            null
                        )
                    }

                    <div className="modal-container" style={{display: this.state.createUser}}>
                        <div style={{display:"flex", alignItems:"center",}}>
                            <div className="content-title">
                                <i className="fas fa-user-alt" style={{color: "var(--deep-blue)",}}></i>
                                <span>Add a User</span>
                            </div>
                            <div className="modal-exit-container">
                                <i
                                    className="fas fa-times-circle"
                                    onClick={() => {
                                        $("#create-username").val("");
                                        $("#create-password").val("");
                                        $("#create-email-address").val("");
                                        $("#create-permissions").val("");
                                        $("#create-preferred-name").val("");

                                        this.setState({
                                            createUser: "none",
                                        });
                                    }}
                                ></i>
                            </div>
                        </div>
                        <div className="content-text">
                            <span>Username:</span>
                            <input id="create-username"></input>
                        </div>
                        <div className="content-text">
                            <span>Password:</span>
                            <input
                                id="create-password"
                                type="password"    
                            ></input>
                        </div>
                        <div className="content-text">
                            <span>Preferred name:</span>
                            <input id="create-preferred-name"></input>
                        </div>
                        <div className="content-text">
                            <span>Email address:</span>
                            <input id="create-email-address"></input>
                        </div>
                        <div className="content-text">
                            <span>Permissions:</span>
                            <select id="create-permissions">
                                <option>View Only</option>
                                <option>Edit</option>
                                <option>Admin</option>
                            </select>
                        </div>
                        <div>
                            <div
                                className="content-button"
                                style={{marginBottom: 0,}}
                                onClick={() => {
                                    let username = $("#create-username").val();
                                    let password = $("#create-password").val();
                                    let emailAddress = $("#create-email-address").val();
                                    let preferredName = $("#create-preferred-name").val();
                                    let permissions = $("#create-permissions").val();

                                    if (permissions === "View Only") {
                                        permissions = 0;
                                    } else if (permissions === "Edit") {
                                        permissions = 1;
                                    } else if (permissions === "Admin") {
                                        permissions = 2;
                                    } else {
                                        permissions = 0;
                                    };

                                    fetch(`${constants.backendAddress}/api/create-user/?username=${username}&password=${password}&email_address=${emailAddress}
                                        &permissions=${permissions}&preferred_name=${preferredName}&initiator=${this.state.userSettings['id']}`).then((response) => {
                                        return response.json();
                                    }).then((response) => {
                                        if (typeof response["response"] === "string") {
                                            this.setState({
                                                errorMessage: response["response"],
                                            });
                                        } else {
                                            $("#create-username").val("");
                                            $("#create-password").val("");
                                            $("#create-email-address").val("");
                                            $("#create-preferred-name").val("");
                                            $("#create-permissions").val("");

                                            this.getUsers();

                                            this.setState({
                                                createUser: "none",
                                                errorMessage: null,
                                            });
                                        };
                                    });
                                }}
                            >
                                Add
                            </div>
                        </div>
                        {
                            this.state.errorMessage ? (
                                <div className="content-error-text">{this.state.errorMessage}</div>
                            ) : (
                                null
                            )
                        }
                    </div>
                    {
                        this.state.inviteEmail ? (
                            <div className="modal-container">
                                <div style={{display:"flex", alignItems:"center",}}>
                                    <div className="content-title">
                                        <i className="fas fa-user-alt" style={{color: "var(--deep-blue)",}}></i>
                                        <span>Invite by Email</span>
                                    </div>
                                    <div className="modal-exit-container">
                                        <i
                                            className="fas fa-times-circle"
                                            onClick={() => {
                                                this.setState({
                                                    inviteEmail: false,
                                                });
                                            }}
                                        ></i>
                                    </div>
                                </div>
                                <div className="content-text">
                                    <span>Email address:</span>
                                    <input id="invite-email-address"></input>
                                </div>
                                <div className="content-text">
                                    <span>Permissions:</span>
                                    <select id="invite-permissions">
                                        <option>View Only</option>
                                        <option>Edit</option>
                                        <option>Admin</option>
                                    </select>
                                </div>
                                <div>
                                    <div
                                        className="content-button"
                                        style={{marginBottom: 0,}}
                                        onClick={() => {
                                            let emailAddress = $("#invite-email-address").val();
                                            let permissions = $("#invite-permissions").val();

                                            if (permissions === "View Only") {
                                                permissions = 0;
                                            } else if (permissions === "Edit") {
                                                permissions = 1;
                                            } else if (permissions === "Admin") {
                                                permissions = 2;
                                            } else {
                                                permissions = 0;
                                            };

                                            if (this.validateEmail(emailAddress)) {
                                                this.setState({
                                                    inviteEmail: false,
                                                });

                                                fetch(`${constants.backendAddress}/api/invite-email/?email_address=${emailAddress}&permissions=${permissions}&initiator=${this.state.userSettings['id']}`);
                                            };
                                        }}
                                    >
                                        Invite
                                    </div>
                                </div>
                                {
                                    this.state.errorMessage ? (
                                        <div className="content-error-text">{this.state.errorMessage}</div>
                                    ) : (
                                        null
                                    )
                                }
                            </div>
                        ) : null
                    }
                    {
                        this.state.createMachine ? (
                            <div className="modal-container">
                                <div style={{display:"flex", alignItems:"center",}}>
                                    <div className="content-title">
                                        <i className="fas fa-user-alt" style={{color: "var(--deep-blue)",}}></i>
                                        <span>Add Machine</span>
                                    </div>
                                    <div className="modal-exit-container">
                                        <i
                                            className="fas fa-times-circle"
                                            onClick={() => {
                                                $("#create-machine").val("");

                                                this.setState({
                                                    createMachine: false,
                                                });
                                            }}
                                        ></i>
                                    </div>
                                </div>
                                <div className="content-text">
                                    <span>Name:</span>
                                    <input id="create-name"></input>
                                </div>
                                <div>
                                    <div
                                        className="content-button"
                                        style={{marginBottom: 0,}}
                                        onClick={() => {
                                            let name = $("#create-name").val();
                                            let machines = [...this.state.machines, name];

                                            fetch(`${constants.backendAddress}/api/create-machine/?machines=${JSON.stringify(machines)}`).then((response) => {
                                                return response.json();
                                            }).then(() => {
                                                this.setState({
                                                    createMachine: false,
                                                });
                                                this.getMachines();
                                            });
                                        }}
                                    >
                                        Add
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                    </> 
                </div>
            </>
        );
    };
};

export default UserManager;
