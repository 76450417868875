// Libraries
import React from "react";

// Modules
import "../App.css";

class FooterButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        return (
            <>
                {/* Footer button */}
                <div
                        id="footer-button"
                        onClick={() => {
                            this.props.showModal();
                        }}
                    >
                        {
                            this.props.name === "Help" ? (
                                <i className="fas fa-question-circle"></i>
                            ) : (
                                <i className="fas fa-cog"></i>
                            )
                        }
                        {this.props.name}
                    </div>
            </>
        );
    };
};

export default FooterButton;
